import React from 'react';
import close from './../assets/close.svg';
import right from './../assets/right.svg';
import { Box, Text, Link, Image, Button } from '@chakra-ui/react';
import logo from './../assets/logo.png';
import { Link as ReachLink } from 'react-router-dom';
// import facebookIcon from './../assets/facebook.svg';
// import instagramIcon from './../assets/instagram.svg';
import linkedInIcon from './../assets/linkedIn.svg';
// import twitterIcon from './../assets/twitter.svg';
import youtubeIcon from './../assets/youtube.svg';

const LeftSideBar = props => {
  return (
    <Box bg="white" overflow="auto" height="100vh">
      <Box display="flex" alignItems="center" pl="4" py="2">
        <Image src={logo} />
        <Button
          display={{ base: 'flex', lg: 'none' }}
          background="transparent"
          ml="auto"
          mr="4"
          onClick={() => props.setMenuOpen(false)}
        >
          <Image src={close} w="3" mx="auto" />
        </Button>
      </Box>
      <Box pl="4" fontSize="sm" my="1" pr="8" color="#13344C">
        {props.staticLinks.map((oneLink, index) => {
          return (
            <Box
              to={{ pathname: oneLink.pathname, hash: oneLink.hash }}
              as={ReachLink}
              onClick={() => {
                props.setMenuOpen(false);
                props.scrollToId(oneLink.hash, props.history);
              }}
              _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
              key={index}
            >
              <Text display="flex" py="4">
                {oneLink.label}
                <Image src={right} w="1.5" ml="auto" />
              </Text>
            </Box>
          );
        })}
        <hr />
      </Box>
      {props.isLoggedIn ? (
        <>
          <Box pl="4" fontSize="sm" my="1" pr="8" color="#13344C">
            {props.navBarItemsFromDB.map((navBarItem, index) => {
              return (
                <Link
                  to={{
                    pathname: navBarItem.pathname,
                    search: navBarItem.search,
                  }}
                  as={ReachLink}
                  _hover={{ textDecoration: 'none' }}
                  key={index}
                >
                  <Text
                    display="flex"
                    py="4"
                    onClick={() => props.setMenuOpen(false)}
                  >
                    {navBarItem.label}
                    <Image src={right} w="1.5" ml="auto" />
                  </Text>
                </Link>
              );
            })}
            <hr />
            <Link
              _hover={{ textDecoration: 'none' }}
              to="/viewonemember?id=myself"
              as={ReachLink}
              onClick={() => {
                props.setMenuOpen(false);
              }}
            >
              <Text display="flex" py="4">
                My Profile
              </Text>
            </Link>
            <Link
              _hover={{ textDecoration: 'none' }}
              to="/changepassword"
              as={ReachLink}
              onClick={() => {
                props.setMenuOpen(false);
              }}
            >
              <Text display="flex" py="4">
                Change Password
              </Text>
            </Link>
            <Link
              onClick={() => {
                props.logout1();
                props.setMenuOpen(false);
              }}
            >
              <Text display="flex" py="4">
                Logout
              </Text>
            </Link>
            <Link
              _hover={{ color: 'white', background: '#00223E' }}
              onClick={() => {
                props.logoutAll();
                props.setMenuOpen(false);
              }}
            >
              <Text display="flex" py="4">
                Logout All Sessions
              </Text>
            </Link>
            <hr />
          </Box>
        </>
      ) : (
        <Link
          pl="4"
          to="/login"
          as={ReachLink}
          onClick={() => {
            props.setMenuOpen(false);
          }}
        >
          <Button px="2" bg="#00223E" color="white" mt="6">
            Login
          </Button>
        </Link>
      )}
      <Box pl="4" pb="5">
        <Text mt="6" mb="3" fontSize="sm" color="#13344C">
          Get vConnect App (Coming Soon!)
        </Text>
        {/* <Link><Image src={playstore} w={40} /></Link> */}
        <Text mt="6" mb="3" fontSize="sm" color="#13344C">
          Follow us
        </Text>
        <Box display="flex">
          {/* KEEP THIS TO ADD LINKS LATER */}
          {/* <Image
            bg="#367A91"
            src={facebookIcon}
            w="5"
            mr="3"
            borderRadius="sm"
          />
          <Image
            bg="#367A91"
            src={instagramIcon}
            w="5"
            mr="3"
            borderRadius="sm"
          />
          <Image
            bg="#367A91"
            src={twitterIcon}
            w="5"
            mr="3"
            borderRadius="sm"
          /> */}
          <Link href="https://www.linkedin.com/groups/12513104/" isExternal>
            <Image
              bg="#367A91"
              src={linkedInIcon}
              w="5"
              mr="3"
              borderRadius="sm"
            />
          </Link>
          <Link
            href="https://www.youtube.com/channel/UC9qxzBDF8elz5OoqEy1CL9w"
            isExternal
          >
            <Image
              bg="#367A91"
              src={youtubeIcon}
              w="5"
              mr="3"
              borderRadius="sm"
            />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default LeftSideBar;
