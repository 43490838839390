import axios from 'axios';
import cookie from 'react-cookies';
import { SESSION_COOKIE_NAME } from '../constants';

const httpClient = ({ method, url, data, responseType }) => {
  return axios({
    method,
    url,
    data,
    headers: { 'X-Session-Cookie': cookie.load(SESSION_COOKIE_NAME) },
    responseType,
  });
};

export default httpClient;
