import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link as ReachLink } from 'react-router-dom';
import { API_HOST } from './../constants.js';
import editIcon from './../assets/edit.svg';
import deleteIcon from './../assets/delete.svg';
import resendInvitation from './../assets/resendInvitation.svg';
import viewIcon from './../assets/view.svg';
import {
  Link,
  Box,
  Spinner,
  Text,
  Flex,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  useToast,
} from '@chakra-ui/react';
import httpClient from './../utils/httpClient.js';
import MemberProfileOverview from './../components/MemberProfileOverview.js';

const ViewOneFamily = () => {
  const [familyInfo, setFamilyInfo] = useState();
  const [didWeGetInfo, setDidWeGetInfo] = useState('loading');
  const [idBeingDeleted, setIdBeingDeleted] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toast = useToast();
  const handleDelete = async () => {
    try {
      let result = await httpClient({
        method: 'PUT',
        url: `${API_HOST}/users/delete`,
        data: { id: idBeingDeleted[0], familyId: idBeingDeleted[1] },
      });
      if (result) {
        setIsModalOpen(false);
        setFamilyInfo(
          familyInfo.filter(item => {
            return item.id !== idBeingDeleted[0];
          })
        );
        return true;
      }
    } catch (err) {
      return false;
    }
  };
  const handleResend = async member => {
    try {
      let result = await httpClient({
        method: 'POST',
        url: `${API_HOST}/invitations/${member.id}`,
      });
      if (result) {
        toast({
          title: 'Invitation resent.',
          description: `An invitation has been resent to ${member.firstName} ${member.motherName} ${member.fatherName} ${member.surname}`,
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        return true;
      }
    } catch (err) {
      toast({
        title: 'Invitation resending failed.',
        description: `An error occured while resending invitation to ${member.firstName} ${member.motherName} ${member.fatherName} ${member.surname}
        `,
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return false;
    }
  };
  const search = useLocation().search;
  useEffect(() => {
    const getInfo = async () => {
      const id = new URLSearchParams(search).get('id');
      const payload = { id: id };
      try {
        let result = await httpClient({
          method: 'GET',
          url: `${API_HOST}/families/${id}`,
          data: payload,
        });
        if (result) {
          setFamilyInfo(result.data);
          setDidWeGetInfo('true');
          return true;
        }
      } catch (err) {
        setDidWeGetInfo('false');
        return false;
      }
    };
    getInfo();
  }, [search]);

  const conditionalContentRendering = () => {
    if (didWeGetInfo === 'loading') {
      return (
        <Flex direction="column" alignItems="center">
          <Text my="4">Loading...</Text>
          <Spinner w="20" h="20" />
        </Flex>
      );
    } else if (didWeGetInfo === 'true') {
      return (
        <Box mx={{ base: '3%', md: '10%' }} color="#367A91" py="2" my="6">
          <Text align="center" color="#13344C" fontWeight="bold">
            Family No. {familyInfo[0].familyId}
          </Text>
          {familyInfo.map(oneMember => {
            return (
              <Box
                borderRadius="xl"
                mt="4"
                boxShadow="dark-lg"
                position="relative"
                key={oneMember.id}
              >
                <MemberProfileOverview userInfo={oneMember} />
                <Flex
                  position={{ lg: 'absolute' }}
                  top="2"
                  right="2"
                  flexDirection={{ base: 'row', lg: 'column' }}
                >
                  <Flex my="2" mx="auto">
                    <Link
                      as={ReachLink}
                      to={`/viewonemember?id=${oneMember.id}`}
                      mx="auto"
                      textAlign="center"
                    >
                      <Image mx="auto" w="8" h="8" src={viewIcon} />
                      View Details
                    </Link>
                  </Flex>
                  {oneMember.editOption ? (
                    <Flex my="2" mx="auto" textAlign="center">
                      <Link
                        as={ReachLink}
                        to={`/editprofile?id=${oneMember.id}`}
                        mx="auto"
                      >
                        <Image mx="auto" w="8" h="8" src={editIcon} />
                        Edit Details
                      </Link>
                    </Flex>
                  ) : null}
                  {oneMember.resendInvitation ? (
                    <Flex my="2" mx="auto" textAlign="center">
                      <Link
                        mx="auto"
                        onClick={() => {
                          handleResend(oneMember);
                        }}
                      >
                        <Image mx="auto" w="8" h="8" src={resendInvitation} />
                        Resend Invitation
                      </Link>
                    </Flex>
                  ) : null}
                  {oneMember.deleteOption ? (
                    <Flex my="2" mx="auto" textAlign="center">
                      <Link
                        onClick={() => {
                          setIsModalOpen(true);
                          setIdBeingDeleted([oneMember.id, oneMember.familyId]);
                        }}
                        mx="auto"
                      >
                        <Image mx="auto" w="8" h="8" src={deleteIcon} />
                        Delete Member
                      </Link>
                    </Flex>
                  ) : null}
                </Flex>
              </Box>
            );
          })}
          {familyInfo[0].addFamilyMemberOption ? (
            <Link
              as={ReachLink}
              to={`/addfamilymember?familyId=${familyInfo[0].familyId}`}
            >
              <Button
                px="2"
                my="6"
                w="100%"
                color="white"
                background="#13344C"
                borderRadius="full"
                type="submit"
                _hover={{
                  color: '#00223E',
                  bg: 'white',
                  border: '1px solid #00223E',
                }}
              >
                Add a Family Member
              </Button>
            </Link>
          ) : null}
        </Box>
      );
    } else {
      return (
        <Box>
          <Text>Oops! No such page exists!</Text>
          <Link as={ReachLink} to="/login">
            Click here to login!
          </Link>
        </Box>
      );
    }
  };

  return (
    <>
      {conditionalContentRendering()}
      <Modal isOpen={isModalOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody color="#13344C">
            Are you sure that you want to delete the family member?
          </ModalBody>
          <ModalFooter>
            <Button
              mr="2"
              mt="5"
              py="2"
              background="white"
              borderRadius="full"
              type="submit"
              color="#13344C"
              border="1px solid #00223E"
              _hover={{
                color: 'white',
                bg: '#00223E',
              }}
              onClick={handleDelete}
            >
              Yes
            </Button>
            <Button
              mt="5"
              py="2"
              background="#13344C"
              borderRadius="full"
              type="submit"
              color="white"
              border="1px solid #00223E"
              _hover={{
                color: '#00223E',
                bg: 'white',
              }}
              mr={3}
              onClick={() => setIsModalOpen(false)}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewOneFamily;
