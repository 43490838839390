import React from 'react';
import { Button, Box, Text, Flex, useToast } from '@chakra-ui/react';
import { API_HOST } from './../../constants.js';
import httpClient from './../../utils/httpClient.js';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyTextInput from './../../components/MyTextInput.js';
import MySelect from './../../components/MySelect.js';

const ChangeRole = () => {
  const toast = useToast();
  return (
    <Box px="2">
      <Text fontSize="xl" p="2" fontWeight="bold">
        Change Role
      </Text>
      <Text p="2" textAlign="justify">
        Only one individual from a family can be set as a Family
        Head/Manager/Administrator. All other family members will be demoted to
        the role User while setting one person to this role.
      </Text>
      <Formik
        initialValues={{
          enrollmentNumber: '',
          newRole: '',
        }}
        validationSchema={Yup.object({
          enrollmentNumber: Yup.string().required('Required'),
          newRole: Yup.string()
            .oneOf(['1', '2', '3'], 'Invalid Role')
            .required('Required'),
        })}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          let payload = {
            enrollmentNumber: values.enrollmentNumber,
            newRole: values.newRole,
          };
          try {
            const saveInfo = await httpClient({
              method: 'PUT',
              url: `${API_HOST}/changeRole`,
              data: payload,
            });
            if (saveInfo) {
              toast({
                title: 'Role changed',
                description: `${saveInfo.data.data}`,
                status: 'success',
                duration: 4000,
                isClosable: true,
              });
              resetForm();
              return true;
            }
          } catch (err) {
            if (err.response.data.errors) {
              setErrors(err.response.data.errors);
              if (typeof err.response.data.errors.data === 'string') {
                toast({
                  title: 'Role changing failed.',
                  description: `${err.response.data.errors.data}`,
                  status: 'error',
                  duration: 4000,
                  isClosable: true,
                });
              }
              resetForm();
              return false;
            }
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          setFieldValue,
          setFieldTouched,
          handleReset,
        }) => (
          <Form>
            <Flex wrap={{ base: 'wrap', md: 'nowrap' }}>
              <Box maxW="15rem">
                <MyTextInput
                  label="Enrollment No."
                  name="enrollmentNumber"
                  type="number"
                />
              </Box>
              <Box>
                <MySelect label="Role" name="newRole">
                  <option value="" disabled></option>
                  <option value="3">Family Head</option>
                  <option value="2">Manager</option>
                  <option value="1">Administrator</option>
                </MySelect>
              </Box>
            </Flex>

            <Button
              mx="2"
              px="4"
              my="2"
              color="white"
              background="#13344C"
              borderRadius="full"
              type="submit"
              border="1px solid #00223E"
              disabled={
                !Object.keys(touched).length ||
                (Object.keys(touched).length && Object.keys(errors).length) ||
                isSubmitting
              }
              _hover={{
                color: '#00223E',
                bg: 'white',
                border: '1px solid #00223E',
              }}
              isLoading={isSubmitting}
            >
              Change Role
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ChangeRole;
