import { Box, FormLabel, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useField } from 'formik';
import DatePicker from 'react-date-picker';

const MyDatePicker = ({ label, ...props }) => {
  const [field, { error, touched }] = useField(props);
  const [isFocused, setIsFocused] = useState(false);
  const handleChange = date => {
    props.setFieldValue(props.name, date);
    setIsFocused(false);
  };
  return (
    <>
      <Box
        m="2"
        pos="relative"
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        border="2px solid"
        borderRadius="2xl"
        borderColor={isFocused ? '#13344C' : '#367A91'}
        color={isFocused ? '#13344C' : '#367A91'}
      >
        <FormLabel
          pos="absolute"
          top="0.8rem"
          left="4"
          fontSize="0.65rem"
          style={{
            transform: '',
            transformOrigin: '',
            transition: 'all 0.2s',
          }}
          htmlFor={props.id || props.name}
        >
          {label}
        </FormLabel>
        <Box pt="7" px="4" h="16" focusBorderColor="none">
          <DatePicker
            name={props.name}
            format="dd/MM/y"
            value={field.value}
            onChange={date => handleChange(date)}
            maxDate={new Date()}
          />
        </Box>
      </Box>
      {touched && error ? (
        <Text
          color="red.500"
          px={2}
          pt={0}
          pb={2}
          className="error"
          textAlign="left"
          ml="2"
        >
          {error}
        </Text>
      ) : null}
    </>
  );
};

export default MyDatePicker;
