import { API_HOST } from './../../constants';
import {
  Box,
  Button,
  Text,
  Flex,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import MyTextInput from './../../components/MyTextInput';
import MySelect from './../../components/MySelect.js';
import * as Yup from 'yup';
import httpClient from '../../utils/httpClient';
import { Redirect } from 'react-router-dom';
import { RELATION_WITH_FAMILY_HEAD } from './../../constants.js';
import { useLocation } from 'react-router-dom';

const AddFamilyMember = () => {
  const search = useLocation().search;
  const familyId = new URLSearchParams(search).get('familyId');
  const emailRegEx = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
  const [userCreationStatus, setUserCreationStatus] = useState(false);
  const selectRelationWithFamilyHead = RELATION_WITH_FAMILY_HEAD.filter(
    relation => relation !== 'Myself'
  );
  return (
    <>
      <Text
        py="2"
        fontWeight="extrabold"
        fontSize="2xl"
        pt={{ base: 2, md: 10 }}
        h="100%"
        width="100%"
        px={{ base: '3%', md: '20%' }}
      >
        Add a family member
      </Text>
      <Box
        pt={{ base: 0, md: 10 }}
        h="100%"
        width="100%"
        px={{ base: '3%', md: '20%' }}
        display={{ base: 'block', md: 'flex' }}
      >
        <Box flex="1">
          <Text color="#367A91" p="2">
            NOTE ON PROVIDING EMAIL ADDRESS FOR FAMILY MEMBERS:
          </Text>
          <UnorderedList color="#367A91" p="2">
            <ListItem>Email is optional.</ListItem>
            <ListItem>
              It is recommended that you add member's email address. Family
              members will receive an invitation if email is provided.
            </ListItem>
            <ListItem>
              Until the family member accept invitation, you can edit details of
              the family member.
            </ListItem>
            <ListItem>
              Once an email is added, an invitation will be sent immediately. In
              case you add a wrong email address, person with the link will be
              able to view community data. So, make sure that the email address
              is correct.
            </ListItem>
          </UnorderedList>
        </Box>
        <Box flex="1">
          <Box
            mx="auto"
            maxW={{ md: '600px' }}
            maxH={{ sm: '100vh', lg: '850px' }}
            borderRadius={{ md: 'lg' }}
            pb="25px"
            overflowY="visible"
          >
            <Box px={{ sm: 2, md: 4 }} mx="auto">
              <Formik
                initialValues={{
                  firstName: '',
                  surname: '',
                  motherName: '',
                  fatherName: '',
                  email: '',
                  relationWithFamilyHead: '',
                }}
                validationSchema={Yup.object({
                  firstName: Yup.string()
                    .max(50, 'Must be 15 characters or less')
                    .required('Required'),
                  fatherName: Yup.string()
                    .max(50, 'Must be 15 characters or less')
                    .required('Required'),
                  motherName: Yup.string()
                    .max(50, 'Must be 15 characters or less')
                    .required('Required'),
                  surname: Yup.string()
                    .max(50, 'Must be 15 characters or less')
                    .required('Required'),
                  email: Yup.string().test(
                    'emailValidation',
                    'Invalid email address',
                    string => {
                      if (string) {
                        return emailRegEx.test(string);
                      }
                      return true;
                    }
                  ),
                  relationWithFamilyHead: Yup.string()
                    .oneOf(
                      selectRelationWithFamilyHead,
                      'Please select a valid relation.'
                    )
                    .required('Required'),
                })}
                onSubmit={async (
                  values,
                  { setSubmitting, setErrors, resetForm }
                ) => {
                  let payload = {
                    firstName: values.firstName,
                    fatherName: values.fatherName,
                    motherName: values.motherName,
                    surname: values.surname,
                    email: values.email.toLowerCase(),
                    relationWithFamilyHead: values.relationWithFamilyHead,
                    familyId: familyId,
                  };
                  console.log('payload', payload);
                  try {
                    const result = await httpClient({
                      method: 'POST',
                      url: `${API_HOST}/users`,
                      data: payload,
                    });

                    if (result) {
                      setUserCreationStatus(result.data.data);
                      resetForm();
                    }
                  } catch (err) {
                    if (err.response.data.errors) {
                      setErrors(err.response.data.errors);
                    }
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValidating,
                  setFieldValue,
                  handleReset,
                }) => (
                  <Form>
                    <MyTextInput
                      label="First Name"
                      name="firstName"
                      type="text"
                    />
                    <MyTextInput
                      label="Father's Name"
                      name="fatherName"
                      type="text"
                    />
                    <MyTextInput
                      label="Mother's Name"
                      name="motherName"
                      type="text"
                    />
                    <MyTextInput label="Surname" name="surname" type="text" />
                    <MyTextInput
                      label="Email (optional)"
                      name="email"
                      type="text"
                    />
                    <Box>
                      <MySelect
                        label="New Member is Family Head's"
                        name="relationWithFamilyHead"
                      >
                        <option disabled></option>
                        {selectRelationWithFamilyHead.map(singleOption => {
                          return (
                            <option value={singleOption} key={singleOption}>
                              {singleOption}
                            </option>
                          );
                        })}
                      </MySelect>
                    </Box>
                    <Flex pt="2">
                      {userCreationStatus ? (
                        <Text color="rgb(29, 161, 242)" mx="auto">
                          <Redirect to={`/viewonefamily?id=${familyId}`} />
                        </Text>
                      ) : (
                        <>
                          <Flex direction="column" w="100%">
                            <Text px="2" py="2">
                              Adding new family member to family number{' '}
                              {familyId}
                            </Text>
                            <Button
                              mx="2"
                              px="2"
                              my="2"
                              w="100%"
                              color="white"
                              background="#13344C"
                              borderRadius="full"
                              type="submit"
                              disabled={
                                !Object.keys(touched).length ||
                                (Object.keys(touched).length &&
                                  Object.keys(errors).length) ||
                                isSubmitting
                              }
                              _hover={{
                                color: '#00223E',
                                bg: 'white',
                                border: '1px solid #00223E',
                              }}
                              isLoading={isSubmitting}
                            >
                              Add a family member
                            </Button>
                          </Flex>
                        </>
                      )}
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddFamilyMember;
