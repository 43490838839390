import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link as ReachLink } from 'react-router-dom';
import { API_HOST } from './../../constants.js';
import { Flex } from '@chakra-ui/react';
import { Doughnut } from 'react-chartjs-2';
import viewIcon from './../../assets/view.svg';
import familyIcon from './../../assets/family.svg';

import {
  Link,
  Box,
  Spinner,
  Text,
  Table,
  Tr,
  Thead,
  Tbody,
  Td,
  Th,
  Image,
} from '@chakra-ui/react';
import httpClient from './../../utils/httpClient.js';

const Dashboard = () => {
  const [dashboardInfo, setDashboardInfo] = useState();
  const [didWeGetInfo, setDidWeGetInfo] = useState('loading');
  const search = useLocation().search;
  useEffect(() => {
    const getInfo = async () => {
      try {
        let result = await httpClient({
          method: 'GET',
          url: `${API_HOST}/commonDashboard`,
        });
        if (result) {
          setDashboardInfo(result.data.data);
          setDidWeGetInfo('true');
          return true;
        }
      } catch (err) {
        setDidWeGetInfo('false');
        return false;
      }
    };
    getInfo();
  }, [search]);

  const conditionalContentRendering = () => {
    if (didWeGetInfo === 'loading') {
      return (
        <Flex direction="column" alignItems="center">
          <Text my="4">Loading...</Text>
          <Spinner w="20" h="20" />
        </Flex>
      );
    } else if (didWeGetInfo === 'true') {
      return (
        <Box px={{ base: '3%', lg: '10%' }} my="4" py="4">
          <Text
            textColor="#13344C"
            fontSize="2xl"
            px="4"
            py="3"
            fontWeight="bold"
          >
            Dashboard
          </Text>
          <Flex wrap={{ base: 'wrap', md: 'nowrap' }}>
            <Box flex="1" my="4">
              <Text
                fontWeight="bold"
                fontSize="xl"
                my="2"
                textAlign="center"
                textColor="#13344C"
              >
                Families
              </Text>
              <Box>
                <Doughnut
                  data={{
                    datasets: [
                      {
                        data: [
                          dashboardInfo.familiesJoined,
                          dashboardInfo.openFamilyInvitations,
                        ],
                        backgroundColor: ['#00223E', '#8DCAFD', '#367A91'],
                      },
                    ],
                    labels: ['Families Joined', 'Open Family Invitations'],
                  }}
                  height={500}
                  options={{
                    maintainAspectRatio: false,
                  }}
                  legend={{
                    labels: {
                      fontColor: '#367A91',
                      fontSize: 18,
                    },
                  }}
                />
              </Box>
            </Box>
            <Box flex="1" my="4">
              <Text
                textColor="#13344C"
                fontWeight="bold"
                fontSize="xl"
                my="2"
                textAlign="center"
              >
                Family Members
              </Text>
              <Box>
                <Doughnut
                  data={{
                    datasets: [
                      {
                        data: [
                          dashboardInfo.usersJoined,
                          dashboardInfo.openUserInvitations,
                          dashboardInfo.nonUsers,
                        ],
                        backgroundColor: ['#00223E', '#8DCAFD', '#367A91'],
                      },
                    ],
                    labels: [
                      'Family Members Joined',
                      'Open Family Member Invitations',
                      'Non-user Family Members',
                    ],
                  }}
                  height={500}
                  // width={500}
                  options={{
                    maintainAspectRatio: false,
                  }}
                  legend={{
                    labels: {
                      fontColor: '#367A91',
                      fontSize: 18,
                    },
                  }}
                />
              </Box>
            </Box>
          </Flex>
          {dashboardInfo.birthdayUsers.length ||
          dashboardInfo.anniversaryUsers.length ? (
            <Box>
              <Text
                fontWeight="bold"
                fontSize="xl"
                mt="6"
                mb="2"
                textColor="#13344C"
              >
                Birthdays and Anniversaries Today
              </Text>
              <Box color="#367A91" overflow="auto">
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Enrollment No.</Th>
                      <Th>Family No.</Th>
                      <Th minW="14rem">Name</Th>
                      <Th>Phone</Th>
                      <Th>Email</Th>
                      <Th>View Details</Th>
                      <Th>View Family</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dashboardInfo.birthdayUsers.length ? (
                      <>
                        <Tr>
                          <Td colSpan="7" color="#13344C" fontWeight="bold">
                            Birthdays
                          </Td>
                        </Tr>
                        {dashboardInfo.birthdayUsers.map(oneSpecialUserId => {
                          const oneSpecialUserDetailsObject = dashboardInfo.userInfo.find(
                            obj => obj.id === oneSpecialUserId
                          );
                          return (
                            <Tr key={oneSpecialUserId}>
                              <Td>{oneSpecialUserId}</Td>
                              <Td>{oneSpecialUserDetailsObject.familyId}</Td>
                              <Td>
                                {oneSpecialUserDetailsObject.firstName +
                                  ' ' +
                                  oneSpecialUserDetailsObject.motherName +
                                  ' ' +
                                  oneSpecialUserDetailsObject.fatherName +
                                  ' ' +
                                  oneSpecialUserDetailsObject.surname}
                              </Td>
                              <Td>{oneSpecialUserDetailsObject.phone}</Td>
                              <Td>{oneSpecialUserDetailsObject.email}</Td>
                              <Td>
                                <Link
                                  as={ReachLink}
                                  to={`/viewonemember?id=${oneSpecialUserId}`}
                                >
                                  <Image src={viewIcon} w="8" h="8" mx="auto" />
                                </Link>
                              </Td>
                              <Td>
                                <Link
                                  as={ReachLink}
                                  to={`/viewonefamily?id=${oneSpecialUserDetailsObject.familyId}`}
                                >
                                  <Image
                                    src={familyIcon}
                                    w="8"
                                    h="8"
                                    mx="auto"
                                  />
                                </Link>
                              </Td>
                            </Tr>
                          );
                        })}
                      </>
                    ) : null}
                    {dashboardInfo.anniversaryUsers.length ? (
                      <>
                        <Tr>
                          <Td colSpan="7" color="#13344C" fontWeight="bold">
                            Anniversaries
                          </Td>
                        </Tr>
                        {dashboardInfo.anniversaryUsers.map(
                          oneSpecialUserId => {
                            const oneSpecialUserDetailsObject = dashboardInfo.userInfo.find(
                              obj => obj.id === oneSpecialUserId
                            );
                            return (
                              <Tr key={oneSpecialUserId}>
                                <Td>{oneSpecialUserId}</Td>
                                <Td>{oneSpecialUserDetailsObject.familyId}</Td>
                                <Td>
                                  {oneSpecialUserDetailsObject.firstName +
                                    ' ' +
                                    oneSpecialUserDetailsObject.motherName +
                                    ' ' +
                                    oneSpecialUserDetailsObject.fatherName +
                                    ' ' +
                                    oneSpecialUserDetailsObject.surname}
                                </Td>
                                <Td>{oneSpecialUserDetailsObject.phone}</Td>
                                <Td>{oneSpecialUserDetailsObject.email}</Td>
                                <Td>
                                  <Link
                                    as={ReachLink}
                                    to={`/viewonemember?id=${oneSpecialUserId}`}
                                  >
                                    <Image
                                      src={viewIcon}
                                      w="8"
                                      h="8"
                                      mx="auto"
                                    />
                                  </Link>
                                </Td>
                                <Td>
                                  <Link
                                    as={ReachLink}
                                    to={`/viewonefamily?id=${oneSpecialUserDetailsObject.familyId}`}
                                  >
                                    <Image
                                      src={familyIcon}
                                      w="8"
                                      h="8"
                                      mx="auto"
                                    />
                                  </Link>
                                </Td>
                              </Tr>
                            );
                          }
                        )}
                      </>
                    ) : null}
                  </Tbody>
                </Table>
              </Box>
            </Box>
          ) : null}
        </Box>
      );
    } else {
      return (
        <Box textAlign="center">
          <Text>Oops! No such page exists!</Text>
          <Link as={ReachLink} to="/login">
            Click here to login!
          </Link>
        </Box>
      );
    }
  };

  return <>{conditionalContentRendering()}</>;
};

export default Dashboard;
