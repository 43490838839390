import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import ChangeRole from './ChangeRole.js';
import DeleteFamily from './DeleteFamily.js';
import OpenFamilyInvitations from './OpenFamilyInvitations.js';

const Administration = () => {
  return (
    <Box color="#367A91" px={{ base: '3%', lg: '10%' }} my="4" py="4">
      <Box>
        <Text
          textColor="#13344C"
          fontSize="2xl"
          px="4"
          py="3"
          fontWeight="bold"
        >
          Administration
        </Text>
      </Box>
      <hr />
      <br />
      <ChangeRole />
      <hr />
      <br />
      <OpenFamilyInvitations />
      <hr />
      <br />
      <DeleteFamily />
    </Box>
  );
};

export default Administration;
