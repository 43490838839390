import React, { useEffect, useState } from 'react';
import { Box, Text, Flex, Spinner, Link } from '@chakra-ui/react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Link as ReachLink } from 'react-router-dom';
import { API_HOST } from './../../constants.js';

const ActivateFamilyHead = () => {
  const [isValidated, setIsValidated] = useState('loading');
  const search = useLocation().search;
  useEffect(() => {
    const verifyMailToken = async () => {
      const email = new URLSearchParams(search).get('email');
      const token = new URLSearchParams(search).get('activationToken');
      const payload = { email: email, token: token };
      try {
        let result = await axios.put(
          `${API_HOST}/invitations/acceptFamilyHeadInvitation`,
          payload
        );
        if (result) {
          setIsValidated('true');
          return true;
        }
      } catch {
        setIsValidated('false');
        return false;
      }
    };
    verifyMailToken();
  }, [search]);

  const conditionalContentRendering = () => {
    if (isValidated === 'loading') {
      return (
        <Box>
          <Text>Activating your account</Text>
          <Spinner w="20" h="20" />
        </Box>
      );
    } else if (isValidated === 'true') {
      return (
        <Box>
          <Text>Successfully activated your account!</Text>
          <Link as={ReachLink} to="/login">
            Click here to login now!
          </Link>
        </Box>
      );
    } else {
      return (
        <Box>
          <Text>Oops! No such page exists!</Text>
          <Link as={ReachLink} to="/login">
            Click here to login!
          </Link>
        </Box>
      );
    }
  };

  return (
    <Flex alignItems="center" h="100vh">
      <Box mx="auto" textAlign="center">
        {conditionalContentRendering()}
      </Box>
    </Flex>
  );
};

export default ActivateFamilyHead;
