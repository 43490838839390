import React from 'react';
import { Box, List, Text } from '@chakra-ui/react';
import { ListItem, UnorderedList, OrderedList } from '@chakra-ui/react';

const ReleaseNotes = () => {
  return (
    <Box
      py="4"
      pt={{ base: 2, md: 10 }}
      h="100%"
      width="100%"
      px={{ base: '3%', md: '20%' }}
      textAlign="justify"
    >
      <Box textAlign="center" fontStyle="italic" id="home" px="2">
        <Text fontSize="4xl" textColor="#13344C">
          Release Notes
        </Text>
      </Box>
      <Box textColor="#367A91" py="5">
        <hr />
      </Box>
      <Box textColor="#367A91">
        <Text py="3">
          The application features, bug fixes and improvements were released in
          the following reverse chronological sequence.
        </Text>
        <UnorderedList>
          <ListItem textColor="#13344C" fontWeight="bold" py={2}>
            Release 4: 8th May 2021 (Minor release: 16 hrs)
            <OrderedList textColor="#367A91" fontWeight="normal">
              <ListItem>
                Development: Features, Change Requests and Bug Fixes
                <UnorderedList>
                  <ListItem>
                    Earlier WhatsApp groups were hardcoded in UI. Now, these are
                    fetched from database.
                  </ListItem>
                  <ListItem>
                    For a given family, when one user's role is elevated to
                    Admin/Manager/Family Head, all other users in the family are
                    assigned the role of User.
                  </ListItem>
                  <ListItem>
                    Roles of only those users who've joined could be changed
                    earlier. This has been changed, and now role can be changed
                    even for members who have not joined.
                  </ListItem>
                  <ListItem>
                    When family head was reinvited by Admin/Manager/Family Head
                    from the family page, the family member email template was
                    being mailed. This has been correct, and now family head
                    template is emailed.
                  </ListItem>
                  <ListItem>
                    New family members added by Admin were getting added to
                    his/her own family. This has been fixed, and Admin/Manager
                    can add family member to any family desired.
                  </ListItem>
                  <ListItem>
                    On small screen (small phone screens, eg. iPhone SE), the
                    dropdown for number of members page got overlaid on the side
                    menu. This has been fixed.
                  </ListItem>
                  <ListItem>
                    Landing page and Administration page mixed case corrected.
                    Also, choice of words improved on Administration page.
                  </ListItem>
                  <ListItem>
                    Admin/Managers can now delete members of any family.
                  </ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                Operational and Administrative
                <UnorderedList>
                  <ListItem>WhatsApp groups list updated.</ListItem>
                  <ListItem>
                    A PPT has been created as a user manual for non-tech-savvy
                    users. This has been shared with the committee members over
                    WhatsApp.
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </OrderedList>
          </ListItem>
          <ListItem textColor="#13344C" fontWeight="bold" py={2}>
            Release 3: 20th April 2021 (Minor release: 4 hrs)
            <OrderedList textColor="#367A91" fontWeight="normal">
              <ListItem>
                Community WhatsApp Groups
                <UnorderedList>
                  <ListItem>
                    A page listing the community WhatsApp groups added.
                  </ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                Navigational Improvement
                <UnorderedList>
                  <ListItem>
                    Removed static links for the logged-in users.
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </OrderedList>
          </ListItem>
          <ListItem textColor="#13344C" fontWeight="bold" py={2}>
            Release 2: 11th April 2021 (Minor release: 8 hrs)
            <OrderedList textColor="#367A91" fontWeight="normal">
              <ListItem>
                Managing User Profile
                <UnorderedList>
                  <ListItem>
                    Admins and managers can edit profiles of those users (family
                    members, family heads, admins, managers) who've not joined.
                  </ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                Exploring Members
                <UnorderedList>
                  <ListItem>
                    To the profile summary while viewing one family and viewing
                    one member, added a field - profile updated on. This field
                    will enable the volunteers to follow-up and get the details
                    updated from the families
                  </ListItem>
                  <ListItem>
                    Bug fixes: On selecting field region, the page crashed. On
                    removing the enrollment number and family number from the
                    details, the links to individual details and family details
                    stopped working.
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </OrderedList>
          </ListItem>
          <ListItem textColor="#13344C" fontWeight="bold" py={2}>
            Release 1: 9th March 2021 (Major release: 300 hrs)
            <OrderedList textColor="#367A91" fontWeight="normal">
              <ListItem>
                User Management
                <UnorderedList>
                  <ListItem>
                    Predefined roles: Admin, Manager, Family Head, Family Member
                  </ListItem>
                  <ListItem>
                    Admin adds managers, managers add family heads, family heads
                    add family members
                  </ListItem>
                  <ListItem>
                    Basic features of inviting members over email, setting a
                    password on invitation acceptance, change password, forgot
                    password
                  </ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                Managing User Profile
                <UnorderedList>
                  <ListItem>
                    Users who've joined can edit their own profile.
                  </ListItem>
                  <ListItem>
                    Until a user does not join the application, admin and
                    manager can keep reinviting users
                  </ListItem>
                  <ListItem>
                    Until a user does not join the application, the family head
                    can keep editing profile for the user
                  </ListItem>
                  <ListItem>
                    Once a user joins the application, no one else can edit the
                    user's profile. The family head can, however, remove/delete
                    the family member from the family.
                  </ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                Exploring Members
                <UnorderedList>
                  <ListItem>
                    Set fields to view for the users, and view multiple members
                    at a time
                  </ListItem>
                  <ListItem>Search on textual fields</ListItem>
                  <ListItem>One family overview</ListItem>
                  <ListItem>View one member details</ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                Managing Families
                <UnorderedList>
                  <ListItem>Add/remove family members</ListItem>
                  <ListItem>Edit profiles of non-user family members</ListItem>
                  <ListItem>Resend invitations to family members</ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                Administrative Features
                <UnorderedList>
                  <ListItem>
                    Reinvite one family (family members + family head) at a time
                  </ListItem>
                  <ListItem>
                    Reinvite 100 oldest open invitations at a time
                  </ListItem>
                  <ListItem>Change roles of users</ListItem>
                  <ListItem>View open family invitations</ListItem>
                  <ListItem>Delete a family</ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                Dashboard
                <UnorderedList>
                  <ListItem>
                    Stats of open family invitations and families joined
                  </ListItem>
                  <ListItem>
                    Stats of open invitations, accepted invitations and non-user
                    family members listed
                  </ListItem>
                  <ListItem>
                    Birthdays and anniversaries for a given date
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </OrderedList>
          </ListItem>
        </UnorderedList>
      </Box>
    </Box>
  );
};

export default ReleaseNotes;
