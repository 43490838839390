import { Box, Button, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyTextInput from './../../components/MyTextInput';
import { API_HOST } from './../../constants.js';
import httpClient from './../../utils/httpClient.js';

const ChangePassword = props => {
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  return (
    <Box>
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          newPasswordConfirmation: '',
        }}
        validationSchema={Yup.object({
          currentPassword: Yup.string().required('Required'),
          newPassword: Yup.string().required('Required'),
          newPasswordConfirmation: Yup.string()
            .required('Required')
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
        })}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          let payload = {
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
            newPasswordConfirmation: values.newPasswordConfirmation,
          };
          try {
            const result = await httpClient({
              method: 'PUT',
              url: `${API_HOST}/users/changePassword`,
              data: payload,
            });
            if (result) {
              setIsPasswordChanged(true);
              resetForm();
            }
          } catch (err) {
            if (err.response.data.errors) {
              setErrors(err.response.data.errors);
            }
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          handleReset,
        }) => (
          <Form>
            <Box maxW={{ sm: '85%', md: '45%', lg: '30%' }} mx="auto" my="10">
              <Text fontSize="xl" my="10" fontWeight="bold" textAlign="center">
                Set New Password
              </Text>

              <MyTextInput
                label="Current Password"
                name="currentPassword"
                type="password"
              />
              {/* Password */}
              <MyTextInput
                label="New Password"
                name="newPassword"
                type="password"
              />
              <MyTextInput
                label="Confirm New Password"
                name="newPasswordConfirmation"
                type="password"
              />
              {!isPasswordChanged ? (
                <>
                  <Box mx="2">
                    <Button
                      flex="1"
                      mt="5"
                      w="100%"
                      px="2"
                      background="#13344C"
                      borderRadius="full"
                      type="submit"
                      color="white"
                      _hover={{
                        color: '#00223E',
                        bg: 'white',
                        border: '1px solid #00223E',
                      }}
                      disabled={
                        !Object.keys(touched).length ||
                        (Object.keys(touched).length &&
                          Object.keys(errors).length) ||
                        isSubmitting
                      }
                      isLoading={isSubmitting}
                    >
                      Save New Password
                    </Button>
                  </Box>
                </>
              ) : (
                <Box mx="2">
                  <Text color="#00223E">Password changed successfully.</Text>
                </Box>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ChangePassword;
