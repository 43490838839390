import React, { useEffect, useState } from 'react';
import { Box, Text, Flex, Spinner, Link } from '@chakra-ui/react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Link as ReachLink } from 'react-router-dom';
import ChangePasswordComponent from './ChangePasswordComponent';
import { API_HOST } from './../../constants.js';

let token = '';
let email = '';
const ActivateFamilyMember = () => {
  const [isValidated, setIsValidated] = useState('loading');
  const search = useLocation().search;
  useEffect(() => {
    const verifyMailToken = async () => {
      email = new URLSearchParams(search).get('email');
      token = new URLSearchParams(search).get('token');
      const payload = { email: email, token: token };
      try {
        let result = await axios.put(
          `${API_HOST}/invitations/verifyFamilyMemberInvitation`,
          payload
        );
        if (result) {
          setIsValidated('true');
          return true;
        }
      } catch {
        setIsValidated('false');
        return false;
      }
    };
    verifyMailToken();
  }, [search]);

  const conditionalContentRendering = () => {
    if (isValidated === 'loading') {
      return (
        <Box>
          <Text>Activating your account</Text>
          <Spinner w="20" h="20" />
        </Box>
      );
    } else if (isValidated === 'true') {
      return (
        <ChangePasswordComponent
          token={token}
          email={email}
          api_based_on_user={`${API_HOST}/users/setPwdWithMemberInvitation`}
        />
      );
    } else {
      return (
        <Box mt="15%" color="#367A91">
          <Text color="tomato">Oops! No such page exists!</Text>
          <Link as={ReachLink} to="/login">
            Click here to login!
          </Link>
          <br />
          <Link as={ReachLink} to="/#joinUs">
            {/* TO DO: This does not scroll  */}
            Click here to know how to join us!
          </Link>
        </Box>
      );
    }
  };
  return (
    <Flex alignItems="center" h="100%" w="100%">
      <Box mx="auto" textAlign="center" w="100%">
        {conditionalContentRendering()}
      </Box>
    </Flex>
  );
};

export default ActivateFamilyMember;
