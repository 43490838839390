import React, { useState } from 'react';
import { ChakraProvider, Flex, Box, Text, Link } from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as ReachLink,
} from 'react-router-dom';
import LandingStaticPage from './containers/LandingStaticPage.js';
import Navbar from './components/Navbar';
import Login from './containers/Login';
import AddFamilyHead from './containers/AddUsers/AddFamilyHead';
import { extendTheme } from '@chakra-ui/react';
import Fonts from './Fonts';
import ForgotPassword from './containers/PasswordAndActivation/ForgotPassword.js';
import ChangeLostPassword from './containers/PasswordAndActivation/ChangeLostPassword.js';
import AddFamilyMember from './containers/AddUsers/AddFamilyMember.js';
import ViewOneMember from './containers/ViewOneMember.js';
import ActivateFamilyHead from './containers/PasswordAndActivation/ActivateFamilyHead';
import ActivateFamilyMember from './containers/PasswordAndActivation/ActivateFamilyMember';
import ViewOneFamily from './containers/ViewOneFamily';
import EditProfile from './containers/EditProfile';
import ChangePassword from './containers/PasswordAndActivation/ChangePassword.js';
import ExploreMembers from './containers/ExploreMembers.js';
import { API_HOST } from './constants.js';
import httpClient from './utils/httpClient.js';
import ITTeam from './containers/ITTeam.js';
import Administration from './containers/Administration/Administration.js';
import ScrollToTop from './components/ScrollToTop.js';
import Dashboard from './containers/Dashboard/Dashboard.js';
import ReleaseNotes from './containers/ReleaseNotes.js';
import WhatsAppGroups from './containers/WhatsAppGroups.js';

const theme = extendTheme({
  fonts: {
    heading: 'Ubuntu',
    body: 'Ubuntu',
  },
});
const checkSession = async setIsLoggedIn => {
  try {
    let result = await httpClient({
      method: 'GET',
      url: `${API_HOST}/sessions`,
    });
    if (result) {
      return setIsLoggedIn(true);
    }
    return setIsLoggedIn(false);
  } catch (err) {
    return setIsLoggedIn(false);
  }
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  checkSession(setIsLoggedIn);
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      {/* Navbar */}
      <Router>
        <ScrollToTop />
        <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        <Box pt="6rem" width="100%" minH="100vh" pb="4rem">
          <Switch>
            <Route path="/login">
              <Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            </Route>
            <Route path="/forgotpassword">
              <ForgotPassword />
            </Route>
            <Route path="/changelostpassword">
              <ChangeLostPassword />
            </Route>
            <Route path="/changepassword">
              <ChangePassword />
            </Route>
            <Route path="/addfamilyhead">
              <AddFamilyHead />
            </Route>
            <Route path="/addfamilymember">
              <AddFamilyMember />
            </Route>
            <Route path="/editprofile">
              <EditProfile />
            </Route>
            <Route path="/viewonemember">
              <ViewOneMember />
            </Route>
            <Route path="/viewonefamily">
              <ViewOneFamily />
            </Route>
            <Route path="/exploremembers">
              <ExploreMembers />
            </Route>
            <Route path="/activatefamilyhead">
              <ActivateFamilyHead />
            </Route>
            <Route path="/activatefamilymember">
              <ActivateFamilyMember />
            </Route>
            <Route path="/administration">
              <Administration />
            </Route>
            <Route path="/itteam">
              <ITTeam />
            </Route>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/whatsappgroups">
              <WhatsAppGroups />
            </Route>
            <Route path="/releasenotes">
              <ReleaseNotes />
            </Route>
            <Route path="/">
              <LandingStaticPage />
            </Route>
          </Switch>
        </Box>
        {/* Footer */}
        <Flex
          textColor="#13344C"
          fontWeight="bold"
          textAlign="right"
          position="absolute"
          bottom="2"
          wrap={{ base: 'wrap', lg: 'nowrap' }}
          width="100%"
        >
          <Link
            to="/itteam"
            as={ReachLink}
            px={2}
            ml={{ base: 'auto', md: '2' }}
            mr="auto"
            textDecoration="underline"
          >
            Special Thanks to Our IT Team
          </Link>
          <Link
            to="/releasenotes"
            as={ReachLink}
            px={2}
            ml="auto"
            mr="auto"
            textDecoration="underline"
          >
            Release Notes
          </Link>
          <Text px={2} ml="auto" mr={{ base: 'auto', md: '2' }}>
            ©2021 Vishanema Dnyati Mandal
          </Text>
        </Flex>
      </Router>
    </ChakraProvider>
  );
}

export default App;
