import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Text,
  Flex,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Spinner,
  Table,
  Tr,
  Thead,
  Tbody,
  Td,
  Th,
} from '@chakra-ui/react';
import { API_HOST } from './../../constants.js';
import httpClient from './../../utils/httpClient.js';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyTextInput from './../../components/MyTextInput.js';

const OpenFamilyInvitations = () => {
  const [isInviteAllModalOpen, setIsInviteAllModalOpen] = useState(false);
  const [
    isOpenFamilyInvitationsModalOpen,
    setIsOpenFamilyInvitationsModalOpen,
  ] = useState(false);
  const ReinviteOne = () => {
    const toast = useToast();
    return (
      <Box>
        <Text mx="2" fontWeight="bold">
          Reinvite Family Head/Manager/Admin
        </Text>
        <Text mx="2" textAlign="justify">
          Reinvite users with the role Family Head/Manager/Admin from a given
          family.
        </Text>
        <Formik
          initialValues={{
            familyId: '',
          }}
          validationSchema={Yup.object({
            familyId: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
            try {
              const sendInvitation = await httpClient({
                method: 'PUT',
                url: `${API_HOST}/invitations/family/${values.familyId}`,
              });
              if (sendInvitation) {
                toast({
                  title: 'Invitation resent',
                  description: `${sendInvitation.data.data}`,
                  status: 'success',
                  duration: 4000,
                  isClosable: true,
                });
                resetForm();
                return true;
              }
            } catch (err) {
              if (err.response.data.errors) {
                setErrors(err.response.data.errors);
                if (typeof err.response.data.errors.data === 'string') {
                  toast({
                    title: 'Invitation resending failed',
                    description: `${err.response.data.errors.data}`,
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                  });
                }
                resetForm();
                return false;
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValidating,
            setFieldValue,
            handleReset,
          }) => (
            <Form>
              <Flex>
                <Box>
                  <MyTextInput
                    label="Family No."
                    name="familyId"
                    type="number"
                  />
                </Box>
              </Flex>

              <Button
                mx="2"
                px="4"
                my="2"
                color="white"
                background="#13344C"
                borderRadius="full"
                type="submit"
                border="1px solid #00223E"
                disabled={
                  !Object.keys(touched).length ||
                  (Object.keys(touched).length && Object.keys(errors).length) ||
                  isSubmitting
                }
                _hover={{
                  color: '#00223E',
                  bg: 'white',
                  border: '1px solid #00223E',
                }}
                isLoading={isSubmitting}
              >
                Reinvite Family Head/Manager/Admin
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    );
  };
  const ReinviteAllModal = () => {
    const toast = useToast();
    const handleReinviteAll = async () => {
      try {
        const reinviteAll = await httpClient({
          method: 'PUT',
          url: `${API_HOST}/invitations`,
        });
        if (reinviteAll) {
          toast({
            title: '100 Oldest Open Invitations Resent',
            description: 'Oldest 100 invitations were resent successfully.',
            status: 'success',
            duration: 4000,
            isClosable: true,
          });
          return true;
        }
      } catch (err) {
        toast({
          title: 'Reinviting All Users Failed.',
          description: 'Please try again later.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    };
    return (
      <Modal isOpen={isInviteAllModalOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody color="#13344C">
            Reinviting 100 oldest invitations will send an email to 100 oldest
            invitations to those who have not yet accepted invitation. Are you
            sure that you want to reinvite oldest 100 open invitations?
          </ModalBody>
          <ModalFooter>
            <Button
              mr="2"
              mt="5"
              py="2"
              background="#13344C"
              borderRadius="full"
              type="submit"
              color="white"
              border="1px solid #00223E"
              _hover={{
                color: '#00223E',
                bg: 'white',
              }}
              onClick={() => {
                handleReinviteAll();
                setIsInviteAllModalOpen(false);
              }}
            >
              Yes
            </Button>
            <Button
              mt="5"
              py="2"
              background="white"
              borderRadius="full"
              type="submit"
              color="#13344C"
              border="1px solid #00223E"
              _hover={{
                color: 'white',
                bg: '#00223E',
              }}
              onClick={() => {
                setIsInviteAllModalOpen(false);
              }}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  const ViewOpenFamilyInvitationsModal = () => {
    const [isLoadingData, setIsLoadingData] = useState('false');
    const [data, setData] = useState();
    useEffect(() => {
      const getInfo = async () => {
        try {
          let openFamilyInvitations = await httpClient({
            method: 'GET',
            url: `${API_HOST}/invitations/`,
          });
          if (openFamilyInvitations) {
            setData(openFamilyInvitations.data.data);
            setIsLoadingData('true');
            return true;
          }
        } catch (err) {
          setIsLoadingData('false');
          return false;
        }
      };
      getInfo();
    }, []);
    const conditionallyRenderedContent = () => {
      if (isLoadingData === 'loading') {
        return (
          <Modal isOpen={isOpenFamilyInvitationsModalOpen}>
            <ModalOverlay />
            <ModalContent>
              <ModalBody color="#13344C">
                <Flex direction="column" alignItems="center">
                  <Text my="4">Loading...</Text>
                  <Spinner w="20" h="20" />
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Button
                  mt="5"
                  py="2"
                  background="white"
                  borderRadius="full"
                  type="submit"
                  color="#13344C"
                  border="1px solid #00223E"
                  _hover={{
                    color: 'white',
                    bg: '#00223E',
                  }}
                  onClick={() => {
                    setIsOpenFamilyInvitationsModalOpen(false);
                  }}
                >
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        );
      } else if (isLoadingData === 'false') {
        return (
          <Modal isOpen={isOpenFamilyInvitationsModalOpen}>
            <ModalOverlay />
            <ModalContent>
              <ModalBody color="#13344C">
                <Flex direction="column" alignItems="center">
                  <Text my="4">Loading failied.</Text>
                  <Text my="4">Please try again later.</Text>
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Button
                  mt="5"
                  py="2"
                  background="white"
                  borderRadius="full"
                  type="submit"
                  color="#13344C"
                  border="1px solid #00223E"
                  _hover={{
                    color: 'white',
                    bg: '#00223E',
                  }}
                  onClick={() => {
                    setIsOpenFamilyInvitationsModalOpen(false);
                  }}
                >
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        );
      } else {
        return (
          <Modal
            isOpen={isOpenFamilyInvitationsModalOpen}
            size="6xl"
            scrollBehavior="inside"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalBody color="#13344C">
                <Box width="100vw">
                  <Table color="#367A91">
                    <Thead>
                      <Tr>
                        <Th>Enrollment No.</Th>
                        <Th>Family No.</Th>
                        <Th>First Name</Th>
                        <Th>Father's Name</Th>
                        <Th>Mother's Name</Th>
                        <Th>Surname</Th>
                        <Th>Email</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data.map((individual, index) => {
                        return (
                          <Tr key={index}>
                            <Td>{individual.id}</Td>
                            <Td>{individual.familyId}</Td>
                            <Td>{individual.firstName}</Td>
                            <Td>{individual.fatherName}</Td>
                            <Td>{individual.motherName}</Td>
                            <Td>{individual.surname}</Td>
                            <Td>{individual.email}</Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button
                  background="white"
                  borderRadius="full"
                  type="submit"
                  color="#13344C"
                  border="1px solid #00223E"
                  _hover={{
                    color: 'white',
                    bg: '#00223E',
                  }}
                  onClick={() => {
                    setIsOpenFamilyInvitationsModalOpen(false);
                  }}
                >
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        );
      }
    };
    return conditionallyRenderedContent();
  };
  return (
    <Box px="2">
      <ViewOpenFamilyInvitationsModal />
      <ReinviteAllModal />
      <Text fontSize="xl" p="2" fontWeight="bold">
        Open Invitations
      </Text>
      <Text p="2" textAlign="justify">
        Several families and members might have been added that might not have
        accepted invitation. Such users, family heads, managers and
        administrators might need to be reinvited.
      </Text>
      <Flex wrap={{ base: 'wrap', md: 'nowrap' }} alignContent="top" mt="4">
        <Box flex="1" pr={{ md: '4' }}>
          <ReinviteOne />
        </Box>
        <Box flex="1" my={{ base: '4', md: '0' }} px={{ md: '4' }}>
          <Text mx="2" fontWeight="bold">
            View Open Invitations
          </Text>
          <Text p="2" textAlign="justify">
            Family heads might have been added as family heads, managers and
            administrators. Such families can be viewed here where these roles
            have not accepted invitation can be viewed here.
          </Text>
          <Button
            mx="2"
            px="4"
            my="2"
            color="white"
            background="#13344C"
            borderRadius="full"
            type="submit"
            border="1px solid #00223E"
            _hover={{
              color: '#00223E',
              bg: 'white',
              border: '1px solid #00223E',
            }}
            onClick={() => {
              setIsOpenFamilyInvitationsModalOpen(true);
            }}
          >
            View Open Family Invitations
          </Button>
        </Box>
        <Box flex="1" pl={{ md: '4' }}>
          <Text mx="2" fontWeight="bold">
            Reinvite 100 Oldest Open Invitations
          </Text>
          <Text p="2" textAlign="justify">
            Send a new invitation to 100 oldest open invitations. This includes
            users who were invited, but didn't accept invitation, and all roles
            - admins, managers, family heads and family members/users.
          </Text>
          <Button
            mx="2"
            px="4"
            my="2"
            color="white"
            background="#13344C"
            borderRadius="full"
            type="submit"
            border="1px solid #00223E"
            _hover={{
              color: '#00223E',
              bg: 'white',
              border: '1px solid #00223E',
            }}
            onClick={() => {
              setIsInviteAllModalOpen(true);
            }}
          >
            Reinvite All
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default OpenFamilyInvitations;
