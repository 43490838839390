import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ListItem, UnorderedList } from '@chakra-ui/react';

const ITTeam = () => {
  return (
    <Box
      py="4"
      pt={{ base: 2, md: 10 }}
      h="100%"
      width="100%"
      px={{ base: '3%', md: '20%' }}
      textAlign="justify"
    >
      <Box textAlign="center" fontStyle="italic" id="home" px="2">
        <Text fontSize="4xl" textColor="#13344C">
          Special Thanks to Our IT Team
        </Text>
      </Box>
      <Box textColor="#367A91" py="5">
        <hr />
      </Box>
      <Box textColor="#367A91">
        <Text py="3">
          The application has been built by donating the valuable time involved
          in its development.
        </Text>
        <UnorderedList>
          <ListItem>
            Development and Testing
            <UnorderedList>
              <ListItem>
                <Box
                  display="inline-block"
                  textColor="#367A91"
                  textDecoration="underline"
                >
                  <a
                    href="https://www.linkedin.com/in/shrikantshet/"
                    target="blank"
                  >
                    Shrikant Shet
                  </a>
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  display="inline-block"
                  textColor="#367A91"
                  textDecoration="underline"
                >
                  <a
                    href="https://www.linkedin.com/in/abhijitghogre/"
                    target="blank"
                  >
                    Abhijit Ghogre
                  </a>
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  display="inline-block"
                  textColor="#367A91"
                  textDecoration="underline"
                >
                  <a
                    href="https://www.linkedin.com/in/shefaleeshet/"
                    target="blank"
                  >
                    Shefalee Shet
                  </a>
                </Box>
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem py="3">
            Guidance
            <UnorderedList>
              <ListItem>
                <Box
                  display="inline-block"
                  textColor="#367A91"
                  textDecoration="underline"
                >
                  <a
                    href="https://www.linkedin.com/in/rahul-metha/"
                    target="blank"
                  >
                    Rahul Metha
                  </a>
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  display="inline-block"
                  textColor="#367A91"
                  textDecoration="underline"
                >
                  <a
                    href="https://www.linkedin.com/in/chaitali-metha-3124bb8b/"
                    target="blank"
                  >
                    Chaitali Metha
                  </a>
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  display="inline-block"
                  textColor="#367A91"
                  textDecoration="underline"
                >
                  <a
                    href="https://www.linkedin.com/in/niraj-doshi-07803550/"
                    target="blank"
                  >
                    Dr. Niraj Doshi
                  </a>
                </Box>
              </ListItem>
            </UnorderedList>
          </ListItem>

          <ListItem py="3">
            Operations and Support
            <UnorderedList>
              <ListItem>
                <Box
                  display="inline-block"
                  textColor="#367A91"
                  textDecoration="underline"
                >
                  <a href="https://www.linkedin.com/in/nrcm/" target="blank">
                    Nitish Metha
                  </a>
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  display="inline-block"
                  textColor="#367A91"
                  textDecoration="underline"
                >
                  <a
                    href="https://www.facebook.com/doshiashish2005"
                    target="blank"
                  >
                    Ashish Doshi
                  </a>
                </Box>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
      </Box>
    </Box>
  );
};

export default ITTeam;
