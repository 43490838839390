import cookie from 'react-cookies';
import { Box, Button, Text, Link } from '@chakra-ui/react';
import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link as ReachLink } from 'react-router-dom';
import axios from 'axios';
import MyTextInput from './../components/MyTextInput';
import { SESSION_COOKIE_NAME, API_HOST } from './../constants.js';
import { Redirect, withRouter } from 'react-router-dom';

const scrollToId = (id, history) => {
  history.push('/');
  setTimeout(() => {
    window.scrollTo(
      0,
      document.getElementById(id).getBoundingClientRect().y +
        window.scrollY -
        80
    );
  });
};

const Login = props => {
  const emailRegEx = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
  return (
    <Box bg="white">
      <Box>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .required('Required')
              .test('emailValidation', 'Invalid email address', string => {
                if (string) {
                  return emailRegEx.test(string);
                }
                return true;
              }),
            password: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            let payload = {
              email: values.email.toLowerCase(),
              password: values.password,
            };
            try {
              const result = await axios.post(`${API_HOST}/sessions`, payload);
              if (result) {
                cookie.save(SESSION_COOKIE_NAME, result.data.data.token, {
                  path: '/',
                });
                props.setIsLoggedIn(true);
              }
            } catch (err) {
              if (err.response.data.errors) {
                setErrors(err.response.data.errors);
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValidating,
          }) => (
            <Form>
              <Box
                // minWidth='300px'
                maxWidth={{ sm: '85%', md: '40%', lg: '30%' }}
                mx="auto"
              >
                <Text
                  fontSize="xl"
                  my="7"
                  fontWeight="bold"
                  textAlign="center"
                  color="#13344C"
                >
                  Login to vConnect
                </Text>
                {/* Email */}
                <MyTextInput label="Email" name="email" type="text" />
                {/* Password */}
                <MyTextInput label="Password" name="password" type="password" />
                {/* Login */}
                <Box px="2">
                  {props.isLoggedIn ? (
                    <Redirect to="/" />
                  ) : (
                    <Button
                      flex="1"
                      mt="5"
                      w="100%"
                      py="2"
                      background="#13344C"
                      borderRadius="full"
                      type="submit"
                      color="white"
                      _hover={{
                        color: '#00223E',
                        bg: 'white',
                        border: '1px solid #00223E',
                      }}
                      disabled={
                        !Object.keys(touched).length ||
                        (Object.keys(touched).length &&
                          Object.keys(errors).length) ||
                        isSubmitting
                      }
                      isLoading={isSubmitting}
                    >
                      Login
                    </Button>
                  )}
                </Box>
                <Box display="flex" my="2">
                  {/* Forgot password */}
                  <Link
                    ml="auto"
                    mr="1"
                    as={ReachLink}
                    to="/forgotpassword"
                    color="#367A91"
                    _hover={{ textDecoration: 'underline' }}
                    _focus={{ textDecoration: 'underline' }}
                  >
                    Forgot password?
                  </Link>
                  <Text color="rgb(27, 149, 224)">·</Text>
                  {/* SignUp */}
                  <Box
                    ml="1"
                    mr="auto"
                    color="#367A91"
                    onClick={() => scrollToId('joinUs', props.history)}
                    _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    Want to join us?
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default withRouter(Login);
