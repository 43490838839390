import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Table,
  Tr,
  Td,
  Thead,
  Tbody,
  Th,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import httpClient from './../utils/httpClient.js';
import { API_HOST } from './../constants.js';

const WhatsAppGroups = () => {
  const [didWeGetInfo, setDidWeGetInfo] = useState('loading');
  const [whatsAppGroupInfo, setWhatsAppGroupInfo] = useState();
  useEffect(() => {
    const getInfo = async () => {
      try {
        let result = await httpClient({
          method: 'GET',
          url: `${API_HOST}/whatsAppGroups`,
        });
        if (result) {
          setWhatsAppGroupInfo(result.data);
          setDidWeGetInfo('true');
          return true;
        }
      } catch (err) {
        setDidWeGetInfo('false');
        return false;
      }
    };
    getInfo();
  }, []);

  const ConditionalContentRendering = () => {
    if (didWeGetInfo === 'loading') {
      return (
        <Flex direction="column" alignItems="center">
          <Text my="4">Loading...</Text>
          <Spinner w="20" h="20" />
        </Flex>
      );
    } else if (didWeGetInfo === 'true') {
      return (
        <>
          <Box overflow="auto" my={2}>
            <Table>
              <Thead>
                <Tr>
                  <Th>Group</Th>
                  <Th>Brief Description</Th>
                  <Th>Admin Contact Details</Th>
                </Tr>
              </Thead>
              <Tbody>
                {whatsAppGroupInfo.map((group, index) => {
                  return (
                    <Tr key={index}>
                      <Td>{group.groupName}</Td>
                      <Td>{group.description}</Td>
                      <Td>{group.admin}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <Text py="2" fontWeight="bold">
            Hey! Interested in starting a new group for a specific topic and
            listing it here? Update our President, go ahead, create the group
            and contact the IT team to list it here.
          </Text>
        </>
      );
    } else {
      return (
        <Box>
          <Text>
            Oops! Some error occured while loading groups! Please refresh the
            page.
          </Text>
        </Box>
      );
    }
  };
  return (
    <Box color="#367A91" px={{ base: '3%', lg: '10%' }} my="4" py="4">
      <Text color="#13344C" fontSize="2xl" py="3" fontWeight="bold">
        Community WhatsApp Groups
      </Text>
      <Text py="3">
        With a view to unite like-minded people, the community WhatsApp groups
        listed below are currently active. To join, kindly contact the mentioned
        individuals.
      </Text>
      <ConditionalContentRendering />
    </Box>
  );
};

export default WhatsAppGroups;
