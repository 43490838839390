import React, { useEffect, useState } from 'react';
import {
  Box,
  Image,
  Text,
  Button,
  Link,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
} from '@chakra-ui/react';
import gear from './../assets/gear.svg';
import LeftSideBar from './LeftSideBar';
import logo from './../assets/logo.png';
import { API_HOST } from './../constants.js';
import httpClient from './../utils/httpClient.js';
import menuBurger from './../assets/menuBurger.svg';
import { Link as ReachLink, withRouter } from 'react-router-dom';

const scrollToId = (id, history) => {
  history.push('/');
  setTimeout(() => {
    window.scrollTo(
      0,
      document.getElementById(id).getBoundingClientRect().y +
        window.scrollY -
        80
    );
  });
};

const Navbar = props => {
  const logout1 = async () => {
    try {
      let result = await httpClient({
        method: 'DELETE',
        url: `${API_HOST}/sessions`,
      });
      if (result) {
        props.setIsLoggedIn(false);
        props.history.replace('/');
        return true;
      }
    } catch (err) {
      return false;
    }
  };
  const logoutAll = async () => {
    try {
      let result = await httpClient({
        method: 'DELETE',
        url: `${API_HOST}/sessions/all`,
      });
      if (result) {
        props.setIsLoggedIn(false);
        props.history.replace('/');
        return true;
      }
    } catch (err) {
      return false;
    }
  };
  const staticLinks = [
    { label: 'Home', pathname: '/', hash: 'home' },
    { label: 'About Us', pathname: '/', hash: 'aboutUs' },
    { label: 'Leadership', pathname: '/', hash: 'leadership' },
    { label: 'Digital Journey', pathname: '/', hash: 'digitalJourney' },
    { label: 'Join Us', pathname: '/', hash: 'joinUs' },
  ];
  const [navBarItemsFromDB, setNavBarItemsFromDB] = useState([]);
  useEffect(() => {
    const fetchNavBarItems = async () => {
      try {
        let result = await httpClient({
          method: 'GET',
          url: `${API_HOST}/sideMenuItems/`,
        });
        if (result) {
          setNavBarItemsFromDB(result.data);
          return true;
        }
        return false;
      } catch (err) {
        return false;
      }
    };
    fetchNavBarItems();
  }, [props.isLoggedIn]);

  const [menuOpen, setMenuOpen] = useState(false);
  const conditionalContentRendering = () => {
    if (props.isLoggedIn) {
      return (
        <>
          {navBarItemsFromDB.map((navBarItem, index) => {
            return (
              <Link
                mx="2"
                to={{
                  pathname: navBarItem.pathname,
                  search: navBarItem.search,
                  hash: navBarItem.hash,
                  state: { fromDashboard: true },
                }}
                as={ReachLink}
                key={index}
                textAlign="center"
              >
                {navBarItem.label}
              </Link>
            );
          })}
          <Menu>
            <MenuButton>
              <Image w="8" h="8" src={gear} my="auto" />
            </MenuButton>
            <MenuList>
              <Link
                _hover={{ textDecoration: 'none' }}
                to="/viewonemember?id=myself"
                as={ReachLink}
              >
                <MenuItem>My Profile</MenuItem>
              </Link>
              <Link
                _hover={{ textDecoration: 'none' }}
                to="/changepassword"
                as={ReachLink}
              >
                <MenuItem>Change Password</MenuItem>
              </Link>
              <MenuItem onClick={logout1}>Logout</MenuItem>
              <MenuItem onClick={logoutAll}>Logout All Devices</MenuItem>
            </MenuList>
          </Menu>
        </>
      );
    } else {
      return (
        <>
          {staticLinks.map((oneLink, index) => {
            return (
              <Box
                mx="2"
                onClick={() => scrollToId(oneLink.hash, props.history)}
                _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                key={index}
                textAlign="center"
              >
                {oneLink.label}
              </Box>
            );
          })}
          <Link to="/login" as={ReachLink}>
            <Button
              px="2"
              bg="#13344C"
              color="white"
              border="1px solid #13344C"
              _hover={{
                color: '#13344C',
                bg: 'white',
              }}
            >
              Login
            </Button>
          </Link>
        </>
      );
    }
  };
  return (
    <Box
      width="100vw"
      position="fixed"
      top="0"
      left="0"
      background="white"
      borderBottom="1px solid gray"
      zIndex="1"
    >
      {/* Navbar */}
      <Box>
        <Box display="flex" alignItems="center" px="8" py="2">
          <Link to="/" as={ReachLink}>
            <Image src={logo} />
          </Link>
          <Text
            mx="6"
            pt="2"
            fontSize="lg"
            display={{ base: 'none', lg: 'flex' }}
            color="#13344C"
            fontWeight="bold"
          >
            Vishanema Community Connect
          </Text>
          <Button
            display={{ base: 'flex', lg: 'none' }}
            bg="none"
            ml="auto"
            onClick={() => {
              setMenuOpen(true);
            }}
          >
            <Image
              display={{ base: 'flex', lg: 'none' }}
              w="4"
              ml="auto"
              src={menuBurger}
            />
          </Button>
          <Box
            ml="auto"
            display={{ base: 'none', lg: 'flex' }}
            alignItems="center"
            color="#367A91"
            fontWeight="bold"
          >
            {conditionalContentRendering()}
          </Box>
        </Box>
        {/*  */}
        {/*  */}
      </Box>
      {/* Side menu for phones */}
      <Box
        position="absolute"
        top="0"
        width="100vw"
        transition="right 0.5s"
        right={menuOpen ? '0' : '100vw'}
        bg="white"
        id="5"
        h="100vh"
        zIndex="3"
      >
        <LeftSideBar
          isLoggedIn={props.isLoggedIn}
          setMenuOpen={setMenuOpen}
          staticLinks={staticLinks}
          navBarItemsFromDB={navBarItemsFromDB}
          logout1={logout1}
          logoutAll={logoutAll}
          scrollToId={scrollToId}
          history={props.history}
        />
      </Box>
    </Box>
  );
};

export default withRouter(Navbar);
