import React from 'react';
import {
  Box,
  Text,
  Table,
  Tr,
  Td,
  Thead,
  Tbody,
  Th,
  Link,
} from '@chakra-ui/react';
import { Link as ReachLink } from 'react-router-dom';
import aboutUsPDF from './../assets/aboutus.pdf';
const LandingStaticPage = () => {
  return (
    <Box textAlign="justify">
      {/* Home */}
      <Box
        py={{ base: '8', sm: '32' }}
        textAlign="center"
        fontStyle="italic"
        id="home"
        px="2"
      >
        <Text fontSize="4xl" textColor="#13344C" py="2">
          Vishanema Dnyati Samaj Bandhav
        </Text>
        <Text fontSize="4xl" textColor="#13344C" py="2">
          विशानेमा ज्ञाती समाज बांधव
        </Text>
        <Text fontSize="4xl" textColor="#13344C" py="2">
          વિશાનેમા જ્ઞાતી સમાજ બાંધવ
        </Text>
      </Box>
      <Box textColor="#367A91" px={{ base: '3%', md: '20%' }} py="5">
        <hr />
      </Box>
      {/* About Us */}
      <Box textColor="#367A91" px={{ base: '3%', md: '20%' }} id="aboutUs">
        <Text textColor="#13344C" fontSize="2xl" py="3" fontWeight="bold">
          About Us
        </Text>
        <Text fontSize="xl" py="2" fontWeight="bold">
          Who are we?
        </Text>
        <Text
          fontSize="xl"
          py="3"
          textAlign="center"
          fontStyle="italic"
          fontWeight="bold"
          textColor="#13344C"
        >
          "Vaishnav Jan To Tene Kahiye, Je Peed Parayi Jane Re......"
        </Text>
        <Text py="2">
          Mehta, Metha, Meta, Sheth, Shet, Gandhi, Doshi, Gujar, Gujarathi,
          Modi, Shah, All the Members of these families come together and make
          one Family named VISHANEMA DNYATI SAMAJ BANDHAV.
        </Text>
        <Text fontSize="xl" py="2" fontWeight="bold">
          History
        </Text>
        <Text py="2">
          As we look into our past, no such data is available stating who we
          are, where are from, and why we migrated.
        </Text>
        <Text py="2">
          From our ancestors, we've heard that we belong to the VAISHNAV
          community, and that we migrated from a place called Modasa in GUJARAT,
          to Konkan and Pune, Vai, Satara region in MAHARASHTRA. Our forefathers
          tried hard to gather our history and record the data. It is also
          believed that we have some of our data in the NASHIK Court. Our god of
          worship is SHRINATHJI, in NATHDWARA in RAJASTHAN as we belong to the
          VAISHNAV community. Also, our family deity is in MODASA named MANGLA
          DEVI. Still there is some confusion with our proper family deity.
        </Text>
        <Text py="2">
          To avoid this confusion, and to pass on our history to our upcoming
          generations we should try to conserve the available information.
          Though the exact places are not known it is said that MEHTA family
          first inhabited in BAMNOLI, METHA in UNEGAON, DOSHI in LONSHI, SHETH
          in PANDHERI and GOWELE, GANDHI in MHAPRAL, GUJAR near PUNE, GUJARATHI
          near PUNE.
        </Text>
        <Box py="2">
          Wanna know more? Here's a PDF detailing out some facts about us:{' '}
          <Box
            display="inline-block"
            textColor="#13344C"
            _hover={{ textDecoration: 'underline' }}
          >
            <a href={aboutUsPDF} target="blank">
              click here
            </a>
          </Box>
          .
        </Box>
      </Box>
      <Box textColor="#367A91" px={{ base: '3%', md: '20%' }} py="5">
        <hr />
      </Box>
      {/* Leadership */}
      <Box textColor="#367A91" px={{ base: '3%', md: '20%' }} id="leadership">
        <Text textColor="#13344C" fontSize="2xl" py="3" fontWeight="bold">
          Leadership
        </Text>
        <Box overflow="auto">
          <Table>
            <Thead>
              <Tr>
                <Th>Region</Th>
                <Th>President</Th>
                <Th>Vice President</Th>
                <Th>Secretary</Th>
                <Th>Treasurer</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Mumbai</Td>
                <Td>Mr. Chimanlal Mehta</Td>
                <Td>Mr. Sukumar Gujar</Td>
                <Td>Mr. Sanjay Sheth</Td>
                <Td>Mr. Ashish Doshi</Td>
              </Tr>
              <Tr>
                <Td>Kokan</Td>
                <Td>Mr. Pradip Gandhi</Td>
                <Td>Mr. Subodh Mehta</Td>
                <Td>Mr. Bhupendra Metha</Td>
                <Td>Mrs. Jayashri Rajendra</Td>
              </Tr>
              <Tr>
                <Td>Pune</Td>
                <Td>Mr. Subhash Gandhi</Td>
                <Td></Td>
                <Td></Td>
                <Td>Mr. Pramod</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
        <Text py="2">
          That's not it though! We're building next generation leaders. The
          sports activities, the debates in groups, the expert talks, and
          several other activities are only possible through several
          contributors who dedicate their time and efforts in making events
          success. So, we've a great team of several leaders who excel at what
          they decide to lead!
        </Text>
      </Box>
      <Box textColor="#367A91" px={{ base: '3%', md: '20%' }} py="5">
        <hr />
      </Box>
      {/* Digital Journey */}
      <Box
        textColor="#367A91"
        px={{ base: '3%', md: '20%' }}
        id="digitalJourney"
      >
        <Text textColor="#13344C" fontSize="2xl" py="3" fontWeight="bold">
          Digital Journey
        </Text>
        <Text fontSize="xl" py="2" fontWeight="bold">
          The initiation
        </Text>
        <Text py="2">
          Our digital journey began when Mr. Nilesh Tulshidas Mehta and Mrs.
          Vidya Nilesh Mehta donated the first website for the community
          dedicated to the fond memories of Late Mrs. Sangita Tulshidas Mehta
          and Late Mr. Raman Hemchand Metha. The first website facilitated an
          online directory for the community, digitizing the earlier paper-based{' '}
          <em>khanesumari</em>. The <em>khanesumari</em> was updated
          periodically when it was maintained and distributed in hard copies.
          The website enabled creating a live version, which could be updated at
          convinience. Also, along with the website, Mr. Nilesh Mehta donated an
          Android app with same features. The app and website combined, listed
          several things like the events, announcements, birthdays,
          anniversaries, festivals and contacts. Overall, it was a great
          initiative which has helped bridging the community.
        </Text>
        <Text fontSize="xl" py="2" fontWeight="bold">
          Imparting knowledge and wisdom
        </Text>
        <Box py="2">
          Moving forward, during the 2020 Covid-lockdown period, the community
          organized several online expert talks which imparted knowledge, made a
          positive atmosphere, and brought people together. Later, the expert
          talks were also streamed live over{' '}
          <Box
            display="inline-block"
            textColor="#13344C"
            _hover={{ textDecoration: 'underline' }}
          >
            <a
              href="https://www.youtube.com/channel/UC9qxzBDF8elz5OoqEy1CL9w"
              target="blank"
            >
              YouTube
            </a>
          </Box>
          , so that the same knowlege could be passed on to the public openly.
        </Box>
        <Text fontSize="xl" py="2" fontWeight="bold">
          Helping hands
        </Text>
        <Text py="2">
          In June 2020, the Kokan region was cyclone-hit, and several messaging
          apps enabled sending necessary help like drinking water, food and
          medical supplies. The region was devastated and will take decades to
          recover from the gross loss suffered.
        </Text>
        <Text fontSize="xl" py="2" fontWeight="bold">
          Enabling interactions and mentoring
        </Text>
        <Box py="2">
          The year 2020 made a significant impact as the Covid-lockdown gave
          community a time to retrospect. Several groups are now available over
          messaging platforms and bring like-minded individuals together. Today,
          our community has messaging groups for{' '}
          <strong>
            <em>
              Jobs/Placements, Equity Trading and Investments, an IT Cell, a
              Doctors' Group and a Legal Cell to name a few.
            </em>
          </strong>{' '}
          We have a{' '}
          <Box
            display="inline-block"
            textColor="#13344C"
            _hover={{ textDecoration: 'underline' }}
          >
            <a href="https://www.linkedin.com/groups/12513104/" target="blank">
              LinkedIn group
            </a>
          </Box>{' '}
          too!
        </Box>
        <Text fontSize="xl" py="2" fontWeight="bold">
          Improving and expanding digital base
        </Text>
        <Box py="2">
          The major issues in the old web application and mobile application was
          that the community did not own the source code, did not have access to
          the server and only had login option for family head. As a result,
          making small changes was a costly affair, and, having better places to
          spend our resources into, the community restrained from adding
          features. The application lacked aspects to mobilize community. With
          these constrains, the community decided on creating its own web
          application and mobile application. The current application has been
          created in the form of donating time of individuals. The intellectual
          property of the application belongs to the community. New features can
          be added, since the community has access to the source code. Check out
          the{' '}
          <Link
            as={ReachLink}
            to="/releasenotes"
            textColor="#13344C"
            _hover={{ textDecoration: 'underline' }}
          >
            release notes
          </Link>{' '}
          which highlight the features of the current application, and our{' '}
          <Link
            as={ReachLink}
            to="/itteam"
            textColor="#13344C"
            _hover={{ textDecoration: 'underline' }}
          >
            in-house IT team
          </Link>{' '}
          that powers it!
        </Box>
      </Box>
      <Box textColor="#367A91" px={{ base: '3%', md: '20%' }} py="5">
        <hr />
      </Box>
      {/* Join Us */}
      <Box
        textColor="#367A91"
        px={{ base: '3%', md: '20%' }}
        id="joinUs"
        pb="4"
      >
        <Text textColor="#13344C" fontSize="2xl" py="3" fontWeight="bold">
          Join Us
        </Text>
        <Text fontSize="xl" py="2" fontWeight="bold">
          Disclaimer
        </Text>
        <Text py="2">
          Firstly, we're a registered trust with several decades of existence.
          Secondly, being a closed group, and having our enrollment process
          offline, there's an offline process involved to onboard you.
        </Text>
        <Text fontSize="xl" py="2" fontWeight="bold">
          Moving from old website/app to the new one - family heads as well as
          individuals
        </Text>
        <Text py="2">
          The current individual listing is based on the old website/app. So, if
          you've used the old app, we've sent an invitation email to the family
          heads. In case family heads have not received those invitation, please
          contact your regional leaders. In the new website, we've made a
          provision for all family members to log-in. This is so that we'll be
          able to add features and involve individuals in the upcoming releases.
        </Text>
        <Text fontSize="xl" py="2" fontWeight="bold">
          First help point: Family head - emails of individuals yet to be linked
          or added
        </Text>
        <Text py="2">
          Family heads have the option to add family members, as well as edit
          basic information like name and email. So, in case you are unable to
          login, or need to associate your email/update your email with our
          listing, you can simply ask your family head to add/update your email
          to your name. You can then use forgot password button to set a new
          password for your individual account.
        </Text>
        <Text fontSize="xl" py="2" fontWeight="bold">
          Second help point: Regional manager - family yet to be listed
        </Text>
        <Text py="2">
          It is possible that your family has not been updated in the old
          website/app. So, we'll have to add your family head to the new app. In
          this case, you'll have to contact regional manager so that they'll be
          able to add you to the new app.
          {/* Currently, the regional managers are: */}
        </Text>
        {/* <UnorderedList>
          <ListItem>
            Mumbai: <Text color="tomato">TO BE ADDED</Text>
          </ListItem>
          <ListItem>
            Kokan: <Text color="tomato">TO BE ADDED</Text>
          </ListItem>
          <ListItem>
            Pune: <Text color="tomato">TO BE ADDED</Text>
          </ListItem>
          <ListItem>
            Rest of the world: <Text color="tomato">TO BE ADDED</Text>
          </ListItem>
        </UnorderedList> */}
        <Text fontSize="xl" py="2" fontWeight="bold">
          Last resort: Take admin's help
        </Text>
        <Text py="2">
          Admins are available. Your regional manager will forward your issue to
          the admin and the admin will get in touch with you to resolve any and
          all issues! Happy to help!
        </Text>
      </Box>
    </Box>
  );
};

export default LandingStaticPage;
