import React, { useEffect, useState } from 'react';
import { Link as ReachLink } from 'react-router-dom';
import { API_HOST } from './../constants.js';
import { Flex, Image } from '@chakra-ui/react';
import viewIcon from './../assets/view.svg';
import familyIcon from './../assets/family.svg';
import { Icon } from '@chakra-ui/react';
import {
  BsFillSkipBackwardFill,
  BsFillSkipStartFill,
  BsFillSkipEndFill,
  BsFillSkipForwardFill,
} from 'react-icons/bs';
import {
  Link,
  Button,
  Box,
  Table,
  Input,
  Tr,
  Thead,
  Tbody,
  Td,
  Th,
  Spinner,
  Text,
  Select as ChakraSelect,
} from '@chakra-ui/react';
import httpClient from './../utils/httpClient.js';
import Select from 'react-select';

const ExploreMembers = () => {
  const [userInfo, setUserInfo] = useState();
  const [didWeGetInfo, setDidWeGetInfo] = useState('loading');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [totalMembersCount, setTotalMembersCount] = useState();
  const [fieldsUserSaved, setFieldsUserSaved] = useState([
    'id',
    '"familyId"',
    '"firstName"',
    '"fatherName"',
    '"motherName"',
    'surname',
  ]);
  let fields2fetch = [...fieldsUserSaved];
  const dbUserProfileDetails = [
    { key: 1, dbField: 'id', label: 'Enrollment No.', type: 'number' },
    { key: 2, dbField: '"familyId"', label: 'Family No.', type: 'number' },
    { key: 3, dbField: '"firstName"', label: 'First Name', type: 'string' },
    { key: 5, dbField: '"fatherName"', label: "Father's Name", type: 'string' },
    { key: 4, dbField: '"motherName"', label: "Mother's Name", type: 'string' },
    { key: 6, dbField: 'surname', label: 'Surname', type: 'string' },
    { key: 13, dbField: 'gender', label: 'Gender', type: 'enum' },
    { key: 14, dbField: 'email', label: 'Email', type: 'string' },
    { key: 15, dbField: 'phone', label: 'Phone', type: 'string' },
    { key: 23, dbField: 'occupation', label: 'Occupation', type: 'string' },
    { key: 19, dbField: 'address', label: 'Address', type: 'string' },
    { key: 21, dbField: 'region', label: 'Region', type: 'enum' },
    { key: 22, dbField: 'country', label: 'Country', type: 'string' },
    { key: 27, dbField: 'clan', label: 'Clan', type: 'string' },
    { key: 28, dbField: 'education', label: 'Education', type: 'string' },
    { key: 29, dbField: 'dob', label: 'Date of Birth', type: 'date' },
    { key: 30, dbField: '"bloodgroup"', label: 'Blood Group', type: 'enum' },
    {
      key: 7,
      dbField: '"relationWithFamilyHead"',
      label: 'Relation with Family Head',
      type: 'enum',
    },
    {
      key: 25,
      dbField: '"maritalStatus"',
      label: 'Marital Status',
      type: 'enum',
    },
    {
      key: 26,
      dbField: '"weddingDate"',
      label: 'Wedding Date',
      type: 'date',
    },
    {
      key: 8,
      dbField: '"preMarriageFirstName"',
      label: 'First Name before Marriage of Female in the Couple',
      type: 'string',
    },
    {
      key: 9,
      dbField: '"postMarriagePartnerFirstName"',
      label: "Spouse's First Name",
      type: 'string',
    },
    {
      key: 10,
      dbField: '"postMarriagePartnerMotherName"',
      label: "Name of Female's Mother",
      type: 'string',
    },
    {
      key: 11,
      dbField: '"postMarriagePartnerFatherName"',
      label: "Name of Female's Father",
      type: 'string',
    },
    {
      key: 12,
      dbField: '"postMarriagePartnerLastName"',
      label: 'Surname of Female (before Wedding)',
      type: 'string',
    },
    {
      key: 24,
      dbField: '"officeAddress"',
      label: 'Office Address',
      type: 'string',
    },
    {
      key: 16,
      dbField: '"facebookLink"',
      label: 'Facebook Link',
      type: 'string',
    },
    {
      key: 17,
      dbField: '"linkedinLink"',
      label: 'LinkedIn Link',
      type: 'string',
    },
    { key: 18, dbField: '"twitterLink"', label: 'Twitter', type: 'string' },
  ];
  const [tableColumnHeadersDataMap, setTableColumnHeadersDataMap] = useState();
  const initializeFields = updateSearchArray => {
    updateSearchArray = fieldsUserSaved.map(item => {
      let item2Object = {
        key: dbUserProfileDetails.find(obj => obj.dbField === item).key,
        label: dbUserProfileDetails.find(obj => obj.dbField === item).label,
        dbField: dbUserProfileDetails.find(obj => obj.dbField === item).dbField,
        value: '',
      };
      return item2Object;
    });
    return updateSearchArray;
  };
  const handleSearchArrayChange = updateSearchArray => {
    updateSearchArray = fieldsUserSaved.map(item => {
      item = item.replace(/"/g, '');
      if (!searchArray.find(obj => obj.dbField.replace(/"/g, '') === item)) {
        let item2Object = {
          key: dbUserProfileDetails.find(
            obj => obj.dbField.replace(/"/g, '') === item
          ).key,
          label: dbUserProfileDetails.find(
            obj => obj.dbField.replace(/"/g, '') === item
          ).label,
          dbField: dbUserProfileDetails.find(
            obj => obj.dbField.replace(/"/g, '') === item
          ).dbField,
          type: dbUserProfileDetails.find(
            obj => obj.dbField.replace(/"/g, '') === item
          ).type,
          value: '',
        };
        return item2Object;
      }
      return searchArray.find(obj => obj.dbField.replace(/"/g, '') === item);
    });
    return updateSearchArray;
  };
  const initializingSearchArray = initializeFields([...fieldsUserSaved]);
  const [searchArray, setSearchArray] = useState([...initializingSearchArray]);
  const [selectedOptions, setSelectedOptions] = useState([
    { value: '"firstName"', label: 'First Name' },
    { value: '"motherName"', label: "Mother's Name" },
    { value: '"fatherName"', label: "Father's Name" },
    { value: 'surname', label: 'Surname' },
  ]);
  let multiSelectOptions = [];
  dbUserProfileDetails.map(field => {
    if (field.dbField === 'id' || field.dbField === `"familyId"`) {
      return null;
    }
    multiSelectOptions.push({ value: field.dbField, label: field.label });
    return null;
  });

  useEffect(() => {
    const getInfo = async () => {
      try {
        let result = await httpClient({
          method: 'GET',
          url: `${API_HOST}/families?page=${page}&limit=${limit}&fieldsUserSaved=${JSON.stringify(
            fieldsUserSaved
          )}&searchArray=${JSON.stringify(searchArray)}`,
        });
        if (result) {
          const updatingSearchArray = handleSearchArrayChange([
            ...fieldsUserSaved,
          ]);
          setSearchArray([...updatingSearchArray]);
          setTableColumnHeadersDataMap(
            fieldsUserSaved.map(item => {
              let item2Object = {
                key: dbUserProfileDetails.find(obj => obj.dbField === item).key,
                label: dbUserProfileDetails.find(obj => obj.dbField === item)
                  .label,
                dbField: dbUserProfileDetails.find(obj => obj.dbField === item)
                  .dbField,
                type: dbUserProfileDetails.find(obj => obj.dbField === item)
                  .type,
              };
              return item2Object;
            })
          );
          setTotalMembersCount(result.data.count);
          setUserInfo(result.data.data);
          setDidWeGetInfo('true');
          return true;
        }
        setDidWeGetInfo('false');
        return false;
      } catch (err) {
        setDidWeGetInfo('false');
        return false;
      }
    };
    getInfo();
  }, [fieldsUserSaved, page, limit]);

  const conditionalContentRendering = () => {
    if (didWeGetInfo === 'loading') {
      return (
        <Flex direction="column" alignItems="center">
          <Text my="4">Loading...</Text>
          <Spinner w="20" h="20" />
        </Flex>
      );
    } else if (didWeGetInfo === 'true') {
      return (
        <Box color="#367A91" px={{ base: '3%', lg: '10%' }} my="4" py="4">
          <Text color="#13344C" fontSize="2xl" py="3" fontWeight="bold">
            Explore Members
          </Text>
          <Box my="2">
            <Text color="#13344C">Select details to fetch</Text>
            <Flex
              my="2"
              alignItems="center"
              wrap={{ base: 'wrap', lg: 'nowrap' }}
            >
              <Box mr={{ lg: '2' }} minW="12rem">
                <Select
                  closeMenuOnSelect={false}
                  defaultValue={selectedOptions}
                  onChange={setSelectedOptions}
                  options={multiSelectOptions}
                  isMulti
                />
              </Box>
              <Box>
                <Button
                  my="2"
                  bg="#13344C"
                  border="2px solid #13344C"
                  color="white"
                  _hover={{ color: '#13344C', bg: 'white' }}
                  onClick={() => {
                    const fields2fetchAfterSaving = selectedOptions.map(
                      item => item.value
                    );
                    if (!fields2fetchAfterSaving.includes('id')) {
                      fields2fetchAfterSaving.unshift('id');
                    }
                    if (!fields2fetchAfterSaving.includes(`"familyId"`)) {
                      fields2fetchAfterSaving.unshift(`"familyId"`);
                    }
                    setDidWeGetInfo('loading');
                    setFieldsUserSaved(fields2fetchAfterSaving);
                    return true;
                  }}
                >
                  Save
                </Button>
              </Box>
            </Flex>
          </Box>
          <Box color="#367A91" overflow="auto">
            <Table>
              <Thead>
                <Tr>
                  {tableColumnHeadersDataMap.map((item, index) => {
                    return (
                      <Th minW="10rem" key={index}>
                        {item.label}
                      </Th>
                    );
                  })}
                  <Th>View Individual Details</Th>
                  <Th>View Family</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  {tableColumnHeadersDataMap.map((item, index) => {
                    return (
                      <Th key={index}>
                        <Input
                          type="text"
                          placeholder={item.label}
                          value={searchArray[index].value}
                          onChange={e => {
                            let tempArray = [...searchArray];
                            tempArray[index].value = e.target.value;
                            setSearchArray(tempArray);
                          }}
                          disabled={item.type === 'string' ? false : true}
                        />
                      </Th>
                    );
                  })}
                  <Th colSpan="2" textAlign="center">
                    <Button
                      my="2"
                      bg="#13344C"
                      border="2px solid #13344C"
                      color="white"
                      _hover={{ color: '#13344C', bg: 'white' }}
                      onClick={() => {
                        setDidWeGetInfo('loading');
                        setFieldsUserSaved(fields2fetch);
                        setPage(0);
                        return true;
                      }}
                    >
                      Search
                    </Button>
                  </Th>
                </Tr>
                {userInfo.map((individual, index) => {
                  return (
                    <Tr key={index}>
                      {tableColumnHeadersDataMap.map((item, index) => {
                        let key2look4 = item.dbField.replace(/"/g, '');
                        return <Td key={index}>{individual[key2look4]}</Td>;
                      })}
                      <Td>
                        <Link
                          as={ReachLink}
                          to={`/viewonemember?id=${individual.id}`}
                        >
                          <Image src={viewIcon} w="8" h="8" mx="auto" />
                        </Link>
                      </Td>
                      <Td>
                        <Link
                          as={ReachLink}
                          to={`/viewonefamily?id=${individual.familyId}`}
                        >
                          <Image src={familyIcon} w="8" h="8" mx="auto" />
                        </Link>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <Flex
            width="100%"
            position="relative"
            alignItems="center"
            wrap={{ base: 'wrap', lg: 'nowrap' }}
          >
            <Flex my="2" alignItems="center" mx={{ base: 'auto', lg: '0' }}>
              <Text minW="36">Members per page</Text>
              <ChakraSelect
                onChange={event => {
                  setLimit(event.target.value);
                }}
                value={limit}
                color="#367A91"
                border="2px solid #367A91"
                borderRadius="2xl"
                _focus={{ borderColor: '#13344C', color: '#13344C' }}
                minW="20"
                mr="2"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </ChakraSelect>
            </Flex>
            <Flex
              position="relative"
              width="100%"
              mx="auto"
              alignItems="center"
              my="2"
            >
              <Button
                ml="auto"
                onClick={() => setPage(0)}
                variant="link"
                disabled={page === 0 ? true : false}
              >
                <Icon as={BsFillSkipBackwardFill} color="#367A91" h={8} w={8} />
              </Button>
              <Button
                onClick={() => setPage(page - 1)}
                variant="link"
                disabled={page === 0 ? true : false}
              >
                <Icon as={BsFillSkipStartFill} color="#367A91" h={8} w={8} />
              </Button>
              <Text>
                Page: {page + 1}/{Math.ceil(totalMembersCount / limit - 1) + 1}
              </Text>
              <Button
                onClick={() => setPage(page + 1)}
                variant="link"
                disabled={
                  page === Math.ceil(totalMembersCount / limit - 1)
                    ? true
                    : false
                }
              >
                <Icon as={BsFillSkipEndFill} color="#367A91" h={8} w={8} />
              </Button>
              <Button
                mr="auto"
                onClick={() =>
                  setPage(Math.ceil(totalMembersCount / limit - 1))
                }
                variant="link"
                disabled={
                  page === Math.ceil(totalMembersCount / limit - 1)
                    ? true
                    : false
                }
              >
                <Icon as={BsFillSkipForwardFill} color="#367A91" h={8} w={8} />
              </Button>
            </Flex>
          </Flex>
        </Box>
      );
    } else {
      return (
        <Box>
          <Text>
            Oops! Some error occured. We cannot reach our server right now.
            Please try again later.
          </Text>
        </Box>
      );
    }
  };

  return <>{conditionalContentRendering()}</>;
};

export default ExploreMembers;
