import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link as ReachLink } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { API_HOST } from './../constants.js';
import {
  Link,
  Box,
  Spinner,
  Text,
  Flex,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from '@chakra-ui/react';
import httpClient from './../utils/httpClient.js';
import { Button, SimpleGrid } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyTextInput from './../components/MyTextInput.js';
import MySelect from './../components/MySelect.js';
import MyDatePicker from '../components/MyDatePicker.js';
import { REGION as selectRegion } from './../constants.js';
import { RELATION_WITH_FAMILY_HEAD } from './../constants.js';
import { MARITAL_STATUS as selectMaritalStatus } from './../constants.js';
import { BLOODGROUP as selectBloodGroup } from './../constants.js';
import { GENDER as selectGender } from './../constants.js';
import { COUNTRY as selectCountry } from './../constants.js';
import moment from 'moment';
import './../assets/custom.css';
import ProfilePicUpload from './../components/ProfilePicUpload.js';
import profilePicIcon from './../assets/profilePic.svg';
import editIcon from './../assets/edit.svg';

const selectRelationWithFamilyHead = RELATION_WITH_FAMILY_HEAD.filter(
  relation => relation !== 'Myself'
);

const EditProfile = () => {
  const [userInfoFromDB, setUserInfoFromDB] = useState();
  const [userCreationStatus, setUserCreationStatus] = useState('');
  const [didWeGetInfo, setDidWeGetInfo] = useState('loading');
  const search = useLocation().search;
  const [profilePicURL, setProfilePicURL] = useState('');
  const [isPicUploadModalOpen, setIsPicUploadModalOpen] = useState(false);
  useEffect(() => {
    const getProfilePic = async profilePicURL => {
      try {
        const image = await httpClient({
          method: 'GET',
          url: `${API_HOST}/profilepics/${profilePicURL}`,
          responseType: 'blob',
        });
        var reader = new window.FileReader();
        reader.readAsDataURL(image.data);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          document
            .getElementById('profileImage')
            .setAttribute('src', imageDataUrl);
        };
        return null;
      } catch (err) {
        return null;
      }
    };
    if (profilePicURL) {
      getProfilePic(profilePicURL);
    }
    return null;
  }, [profilePicURL]);

  useEffect(() => {
    const getInfo = async () => {
      const id = new URLSearchParams(search).get('id');
      const payload = { id: id };
      try {
        let result = await httpClient({
          method: 'GET',
          url: `${API_HOST}/users/${id}`,
          data: payload,
        });
        if (result) {
          if (result.data.relationWithFamilyHead === 'Myself') {
            result.data.isUserFamilyHead = true;
          } else {
            result.data.isUserFamilyHead = false;
          }
          Object.keys(result.data).forEach(key => {
            if (!result.data[key]) {
              result.data[key] = '';
            }
          });
          setUserInfoFromDB(result.data);
          setProfilePicURL(result.data.profilePic);
          setDidWeGetInfo('true');
          return true;
        }
      } catch (err) {
        setDidWeGetInfo('false');
        return false;
      }
    };
    getInfo();
  }, [search]);

  const conditionalContentRendering = () => {
    const emailRegEx = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
    if (didWeGetInfo === 'loading') {
      return (
        <Flex direction="column" alignItems="center">
          <Text my="4">Loading...</Text>
          <Spinner w="20" h="20" />
        </Flex>
      );
    } else if (didWeGetInfo === 'true') {
      return (
        <Box
          py="4"
          pt={{ base: 2, md: 10 }}
          h="100%"
          width="100%"
          px={{ base: '3%', md: '20%' }}
        >
          <>
            <Text py="2" fontWeight="extrabold" fontSize="2xl">
              Edit Profile Info for Family No. {userInfoFromDB.familyId}'s
              Individual Enrollment No. {userInfoFromDB.id}:
            </Text>
            <Modal isOpen={isPicUploadModalOpen}>
              <ModalOverlay />
              <ModalContent>
                <ModalBody color="#13344C">
                  <ProfilePicUpload
                    setIsPicUploadModalOpen={setIsPicUploadModalOpen}
                    setProfilePicURL={setProfilePicURL}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
            <Box mx="auto">
              <Formik
                initialValues={{
                  profilepicUrl: userInfoFromDB.profilePic,
                  firstName: userInfoFromDB.firstName,
                  fatherName: userInfoFromDB.fatherName,
                  motherName: userInfoFromDB.motherName,
                  surname: userInfoFromDB.surname,
                  dob: userInfoFromDB.dob
                    ? moment(userInfoFromDB.dob).toDate()
                    : null,
                  email: userInfoFromDB.email,
                  phone: userInfoFromDB.phone,
                  role: userInfoFromDB.roleId,
                  occupation: userInfoFromDB.occupation,
                  address: userInfoFromDB.address,
                  region: userInfoFromDB.region,
                  country: userInfoFromDB.country,
                  clan: userInfoFromDB.clan,
                  education: userInfoFromDB.education,
                  bloodGroup: userInfoFromDB.bloodgroup,
                  relationWithFamilyHead: userInfoFromDB.relationWithFamilyHead,
                  maritalStatus: userInfoFromDB.maritalStatus,
                  weddingDate: userInfoFromDB.weddingDate
                    ? moment(userInfoFromDB.weddingDate).toDate()
                    : null,
                  officeAddress: userInfoFromDB.officeAddress,
                  facebookLink: userInfoFromDB.facebookLink,
                  linkedinLink: userInfoFromDB.linkedinLink,
                  twitterLink: userInfoFromDB.twitterLink,
                  familyHead_firstName: userInfoFromDB.familyHead.firstName,
                  familyHead_fatherName: userInfoFromDB.familyHead.fatherName,
                  familyHead_motherName: userInfoFromDB.familyHead.motherName,
                  familyHead_surname: userInfoFromDB.familyHead.surname,
                  gender: userInfoFromDB.gender,
                  preMarriageFirstName: userInfoFromDB.preMarriageFirstName,
                  postMarriagePartnerFirstName:
                    userInfoFromDB.postMarriagePartnerFirstName,
                  postMarriagePartnerMotherName:
                    userInfoFromDB.postMarriagePartnerMotherName,
                  postMarriagePartnerFatherName:
                    userInfoFromDB.postMarriagePartnerFatherName,
                  postMarriagePartnerLastName:
                    userInfoFromDB.postMarriagePartnerLastName,
                  isUserFamilyHead: userInfoFromDB.isUserFamilyHead,
                  familyNumber: userInfoFromDB.familyId,
                  enrollmentNumber: userInfoFromDB.id,
                }}
                validationSchema={Yup.object({
                  firstName: Yup.string()
                    .max(50, 'Must be 15 characters or less')
                    .required('Required'),
                  fatherName: Yup.string()
                    .max(50, 'Must be 15 characters or less')
                    .required('Required'),
                  motherName: Yup.string()
                    .max(50, 'Must be 15 characters or less')
                    .required('Required'),
                  surname: Yup.string()
                    .max(50, 'Must be 15 characters or less')
                    .required('Required'),
                  email: Yup.string().test(
                    'emailValidation',
                    'Invalid email address',
                    string => {
                      if (string) {
                        return emailRegEx.test(string);
                      }
                      return true;
                    }
                  ),
                  facebookLink: Yup.string().url('Should be a valid URL'),
                  linkedinLink: Yup.string().url('Should be a valid URL'),
                  twitterLink: Yup.string().url('Should be a valid URL'),
                })}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                  // Submitting using API
                  let payload = {
                    profilepicUrl: profilePicURL,
                    firstName: values.firstName,
                    fatherName: values.fatherName,
                    motherName: values.motherName,
                    surname: values.surname,
                    dob: values.dob
                      ? moment(values.dob).format('YYYY-MM-DD')
                      : null,
                    email: values.email.toLowerCase(),
                    phone: values.phone,
                    role: values.role,
                    occupation: values.occupation,
                    address: values.address,
                    region: values.region,
                    country: values.country,
                    clan: values.clan,
                    education: values.education,
                    bloodGroup: values.bloodGroup,
                    relationWithFamilyHead: values.relationWithFamilyHead,
                    maritalStatus: values.maritalStatus,
                    weddingDate: values.weddingDate
                      ? moment(values.weddingDate).format('YYYY-MM-DD')
                      : null,
                    officeAddress: values.officeAddress,
                    facebookLink: values.facebookLink,
                    linkedinLink: values.linkedinLink,
                    twitterLink: values.twitterLink,
                    familyHead_firstName: values.familyHead_firstName,
                    familyHead_fatherName: values.familyHead_fatherName,
                    familyHead_motherName: values.familyHead_motherName,
                    familyHead_surname: values.familyHead_surname,
                    gender: values.gender,
                    preMarriageFirstName: values.preMarriageFirstName,
                    postMarriagePartnerFirstName:
                      values.postMarriagePartnerFirstName,
                    postMarriagePartnerMotherName:
                      values.postMarriagePartnerMotherName,
                    postMarriagePartnerFatherName:
                      values.postMarriagePartnerFatherName,
                    postMarriagePartnerLastName:
                      values.postMarriagePartnerLastName,
                    isUserFamilyHead: values.isUserFamilyHead,
                    familyNumber: values.familyNumber,
                    enrollmentNumber: values.enrollmentNumber,
                  };
                  try {
                    const saveInfo = await httpClient({
                      method: 'PUT',
                      url: `${API_HOST}/users`,
                      data: payload,
                    });
                    if (saveInfo) {
                      setUserCreationStatus(saveInfo.data.data);
                    }
                  } catch (err) {
                    if (err.response.data.errors) {
                      setErrors(err.response.data.errors);
                    }
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValidating,
                  setFieldValue,
                  handleReset,
                }) => (
                  <Form>
                    <Box alignItems="center" display={{ lg: 'flex' }}>
                      <Box
                        flex="1"
                        position="relative"
                        mx={{ base: 'auto' }}
                        maxW={{ base: '100%', md: '300px' }}
                        py="2"
                        pr={{ lg: '2' }}
                      >
                        <Image
                          id="profileImage"
                          src={profilePicIcon}
                          width="100%"
                          borderRadius="full"
                        />
                        <Link
                          position="absolute"
                          bottom="0"
                          right="0"
                          onClick={() => setIsPicUploadModalOpen(true)}
                        >
                          <Image mx="auto" w="8" h="8" src={editIcon} />
                        </Link>
                      </Box>
                      <Box flex="3" pl={{ md: '2' }} mx={{ md: '10%' }}>
                        <MyTextInput
                          label="First Name"
                          name="firstName"
                          type="text"
                        />
                        <MyTextInput
                          label="Mother's Name"
                          name="motherName"
                          type="text"
                        />

                        <MyTextInput
                          label="Father's Name"
                          name="fatherName"
                          type="text"
                        />
                        <MyTextInput
                          label="Surname"
                          name="surname"
                          type="text"
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Text
                        px="2"
                        pt="6"
                        pb="2"
                        textAlign={{ base: 'center', md: 'left' }}
                        textColor="#367A91"
                        fontWeight="bold"
                      >
                        Contact and Social
                      </Text>
                      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }}>
                        <MyTextInput
                          label="Facebook Link"
                          name="facebookLink"
                          type="url"
                        />
                        <MyTextInput
                          label="LinkedIn Link"
                          name="linkedinLink"
                          type="url"
                        />
                        <MyTextInput
                          label="Twitter"
                          name="twitterLink"
                          type="url"
                        />
                        <MyTextInput label="Phone" name="phone" type="text" />
                        <MyTextInput label="Email" name="email" type="email" />
                      </SimpleGrid>
                      <Text
                        px="2"
                        pt="6"
                        pb="2"
                        textAlign={{ base: 'center', md: 'left' }}
                        textColor="#367A91"
                        fontWeight="bold"
                      >
                        Personal Details
                      </Text>
                      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }}>
                        <MyDatePicker
                          label="Date of Birth"
                          name="dob"
                          setFieldValue={setFieldValue}
                        />
                        <Box>
                          <MySelect label="Gender" name="gender">
                            {selectGender.map(singleOption => {
                              return (
                                <option value={singleOption} key={singleOption}>
                                  {singleOption}
                                </option>
                              );
                            })}
                          </MySelect>
                        </Box>
                        <MyTextInput
                          label="Education"
                          name="education"
                          type="text"
                        />
                        <MyTextInput
                          label="Occupation"
                          name="occupation"
                          type="text"
                        />
                        <Box>
                          <MySelect label="Blood Group" name="bloodGroup">
                            {selectBloodGroup.map(singleOption => {
                              return (
                                <option value={singleOption} key={singleOption}>
                                  {singleOption}
                                </option>
                              );
                            })}
                          </MySelect>
                        </Box>
                        <MyTextInput label="Clan" name="clan" type="text" />
                      </SimpleGrid>
                      <Text
                        px="2"
                        pt="6"
                        pb="2"
                        textAlign={{ base: 'center', md: 'left' }}
                        textColor="#367A91"
                        fontWeight="bold"
                      >
                        Home and Office Address
                      </Text>
                      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }}>
                        <MyTextInput
                          label="Home Address"
                          name="address"
                          type="text"
                        />
                        <MyTextInput
                          label="Office Address"
                          name="officeAddress"
                          type="text"
                        />
                        <Box>
                          <MySelect label="Region" name="region">
                            {selectRegion.map(singleOption => {
                              return (
                                <option value={singleOption} key={singleOption}>
                                  {singleOption}
                                </option>
                              );
                            })}
                          </MySelect>
                        </Box>
                        <Box>
                          <MySelect label="Country" name="country">
                            {selectCountry.map(singleOption => {
                              return (
                                <option value={singleOption} key={singleOption}>
                                  {singleOption}
                                </option>
                              );
                            })}
                          </MySelect>
                        </Box>
                      </SimpleGrid>
                      <Text
                        px="2"
                        pt="6"
                        pb="2"
                        textAlign={{ base: 'center', md: 'left' }}
                        textColor="#367A91"
                        fontWeight="bold"
                      >
                        Spouse Details
                      </Text>
                      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }}>
                        <Box>
                          <MySelect label="Marital Status" name="maritalStatus">
                            {selectMaritalStatus.map(singleOption => {
                              return (
                                <option value={singleOption} key={singleOption}>
                                  {singleOption}
                                </option>
                              );
                            })}
                          </MySelect>
                        </Box>
                      </SimpleGrid>
                      {values.maritalStatus === 'Married' ||
                      values.maritalStatus === 'Widowed' ? (
                        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }}>
                          <MyDatePicker
                            label="Wedding Date"
                            name="weddingDate"
                            setFieldValue={setFieldValue}
                          />
                          {values.gender === 'Female' ? (
                            <MyTextInput
                              label="Your Premarriage First Name"
                              name="preMarriageFirstName"
                              type="text"
                            />
                          ) : (
                            <MyTextInput
                              label="Spouse's Premarriage First Name"
                              name="preMarriageFirstName"
                              type="text"
                            />
                          )}
                          <MyTextInput
                            label="Spouse's First Name"
                            name="postMarriagePartnerFirstName"
                            type="text"
                          />
                          <MyTextInput
                            label="Name of Spouse's Mother"
                            name="postMarriagePartnerMotherName"
                            type="text"
                          />
                          <MyTextInput
                            label="Name of Spouse's Father"
                            name="postMarriagePartnerFatherName"
                            type="text"
                          />
                          <MyTextInput
                            label="Spouse's Surname"
                            name="postMarriagePartnerLastName"
                            type="text"
                          />
                        </SimpleGrid>
                      ) : null}

                      <Text
                        px="2"
                        pt="6"
                        pb="2"
                        textAlign={{ base: 'center', md: 'left' }}
                        textColor="#367A91"
                        fontWeight="bold"
                      >
                        Family Head
                      </Text>
                      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }}>
                        {values.isUserFamilyHead ? (
                          <Box>
                            <MySelect
                              label="Relation with Family Head"
                              name="relationWithFamilyHead"
                              disabled
                            >
                              <option>Myself</option>
                            </MySelect>
                          </Box>
                        ) : (
                          <>
                            <Box>
                              <MySelect
                                label="I am Family Head's"
                                name="relationWithFamilyHead"
                              >
                                {selectRelationWithFamilyHead.map(
                                  singleOption => {
                                    return (
                                      <option
                                        value={singleOption}
                                        key={singleOption}
                                      >
                                        {singleOption}
                                      </option>
                                    );
                                  }
                                )}
                              </MySelect>
                            </Box>
                            <MyTextInput
                              label="First Name of Family Head"
                              name="familyHead_firstName"
                              type="text"
                              disabled={true}
                            />
                            <MyTextInput
                              label="Name of Family Head's Father"
                              name="familyHead_fatherName"
                              type="text"
                              disabled={true}
                            />
                            <MyTextInput
                              label="Name of Family Head's Mother"
                              name="familyHead_motherName"
                              type="text"
                              disabled={true}
                            />
                            <MyTextInput
                              label="Family Head's Surname"
                              name="familyHead_surname"
                              type="text"
                              disabled={true}
                            />
                          </>
                        )}
                      </SimpleGrid>
                    </Box>
                    {/*  */}
                    {/* Submit and Reset */}
                    <Flex pt="2">
                      {userCreationStatus ? (
                        <Text color="rgb(29, 161, 242)" mx="auto">
                          <Redirect
                            to={`/viewonemember?id=${values.enrollmentNumber}`}
                          />
                        </Text>
                      ) : (
                        <>
                          <Button
                            mx="2"
                            px="2"
                            my="2"
                            w="100%"
                            color="white"
                            background="#13344C"
                            borderRadius="full"
                            type="submit"
                            disabled={
                              !Object.keys(touched).length ||
                              (Object.keys(touched).length &&
                                Object.keys(errors).length) ||
                              isSubmitting
                            }
                            _hover={{
                              color: '#00223E',
                              bg: 'white',
                              border: '1px solid #00223E',
                            }}
                            isLoading={isSubmitting}
                          >
                            Save
                          </Button>
                          <Button
                            mx="2"
                            px="2"
                            my="2"
                            w="100%"
                            background="white"
                            color="#13344C"
                            border="1px solid #13344C"
                            borderRadius="full"
                            onClick={() => {
                              handleReset();
                              setProfilePicURL(userInfoFromDB.profilePic);
                            }}
                            disabled={isSubmitting}
                          >
                            Reset
                          </Button>
                        </>
                      )}
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Box>
          </>
        </Box>
      );
    } else {
      return (
        <Box>
          <Text>Oops! No such page exists!</Text>
          <Link as={ReachLink} to="/">
            Click here to go to home page!
          </Link>
        </Box>
      );
    }
  };

  return <>{conditionalContentRendering()}</>;
};

export default EditProfile;
