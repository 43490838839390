import React, { useState } from 'react';
import {
  Button,
  Box,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { API_HOST } from './../../constants.js';
import httpClient from './../../utils/httpClient.js';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyTextInput from './../../components/MyTextInput.js';

const DeleteFamily = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toast = useToast();
  return (
    <Box px="2">
      <Text fontSize="xl" p="2" fontWeight="bold">
        Delete A Family
      </Text>
      <Text p="2" textAlign="justify">
        Note that if a family is deleted, all users in the family will be
        deleted.
      </Text>
      <Formik
        initialValues={{
          familyNumber: '',
        }}
        validationSchema={Yup.object({
          familyNumber: Yup.string().required('Required'),
        })}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          try {
            const saveInfo = await httpClient({
              method: 'DELETE',
              url: `${API_HOST}/families/${values.familyNumber}`,
            });
            if (saveInfo) {
              toast({
                title: 'Family deleted',
                description: `${saveInfo.data.data}`,
                status: 'success',
                duration: 4000,
                isClosable: true,
              });
              resetForm();
              return true;
            }
          } catch (err) {
            if (err.response.data.errors) {
              setErrors(err.response.data.errors);
              if (typeof err.response.data.errors.data === 'string') {
                toast({
                  title: 'Family deletion failed.',
                  description: `${err.response.data.errors.data}`,
                  status: 'error',
                  duration: 4000,
                  isClosable: true,
                });
              }
              return false;
            }
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          setFieldValue,
          handleReset,
        }) => (
          <Form>
            <Box maxW="15rem">
              <MyTextInput
                label="Family No."
                name="familyNumber"
                type="number"
              />
            </Box>
            <Button
              mx="2"
              px="4"
              my="auto"
              color="white"
              background="#13344C"
              borderRadius="full"
              // type="submit"
              border="1px solid #00223E"
              disabled={
                !Object.keys(touched).length ||
                (Object.keys(touched).length && Object.keys(errors).length) ||
                isSubmitting
              }
              _hover={{
                color: '#00223E',
                bg: 'white',
                border: '1px solid #00223E',
              }}
              isLoading={isSubmitting}
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Delete Family
            </Button>
            <Modal isOpen={isModalOpen}>
              <ModalOverlay />
              <ModalContent>
                <ModalBody color="#13344C">
                  Are you sure that you want to delete the family?
                </ModalBody>
                <ModalFooter>
                  <Button
                    mr="2"
                    mt="5"
                    py="2"
                    background="white"
                    borderRadius="full"
                    type="submit"
                    color="#13344C"
                    border="1px solid #00223E"
                    _hover={{
                      color: 'white',
                      bg: '#00223E',
                    }}
                    onClick={() => {
                      handleSubmit();
                      setIsModalOpen(false);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    mt="5"
                    py="2"
                    background="#13344C"
                    borderRadius="full"
                    type="submit"
                    color="white"
                    border="1px solid #00223E"
                    _hover={{
                      color: '#00223E',
                      bg: 'white',
                    }}
                    mr={3}
                    onClick={() => {
                      setIsModalOpen(false);
                      handleReset();
                    }}
                  >
                    No
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default DeleteFamily;
