import { Box, Button, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import MyTextInput from './../../components/MyTextInput';
import * as Yup from 'yup';
import { API_HOST } from './../../constants.js';
import httpClient from '../../utils/httpClient.js';

const AddFamilyHead = () => {
  const emailRegEx = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
  const [userCreationStatus, setUserCreationStatus] = useState('');
  return (
    <>
      <Box pt={{ sm: 0, md: 10 }} h="100%" width="100%">
        <Box
          mx="auto"
          maxW={{ md: '600px' }}
          maxH={{ sm: '100vh', lg: '850px' }}
          borderRadius={{ md: 'lg' }}
          pb="25px"
          overflowY="visible"
        >
          <Box px={{ sm: 2, md: 4 }} mx="auto">
            <Formik
              initialValues={{
                firstName: '',
                surname: '',
                motherName: '',
                fatherName: '',
                email: '',
                password: '',
                passwordConfirmation: '',
              }}
              validationSchema={Yup.object({
                firstName: Yup.string()
                  .max(50, 'Must be 15 characters or less')
                  .required('Required'),
                fatherName: Yup.string()
                  .max(50, 'Must be 15 characters or less')
                  .required('Required'),
                motherName: Yup.string()
                  .max(50, 'Must be 15 characters or less')
                  .required('Required'),
                surname: Yup.string()
                  .max(50, 'Must be 15 characters or less')
                  .required('Required'),
                email: Yup.string()
                  .required('Required')
                  .test('emailValidation', 'Invalid email address', string => {
                    if (string) {
                      return emailRegEx.test(string);
                    }
                    return true;
                  }),
                password: Yup.string()
                  .required('Required')
                  .min(
                    8,
                    'Password too short - should be 8 character minimum.'
                  ),
                passwordConfirmation: Yup.string()
                  .required('Required')
                  .oneOf([Yup.ref('password'), null], 'Passwords must match'),
              })}
              onSubmit={async (
                values,
                { setSubmitting, setErrors, resetForm }
              ) => {
                let payload = {
                  firstName: values.firstName,
                  fatherName: values.fatherName,
                  motherName: values.motherName,
                  surname: values.surname,
                  password: values.password,
                  passwordConfirmation: values.passwordConfirmation,
                  email: values.email.toLowerCase(),
                };
                try {
                  const result = await httpClient({
                    method: 'POST',
                    url: `${API_HOST}/families`,
                    data: payload,
                  });
                  if (result) {
                    setUserCreationStatus(result.data.data);
                    resetForm();
                  }
                } catch (err) {
                  if (err.response.data.errors) {
                    setErrors(err.response.data.errors);
                  }
                  if (err.response.status === 401) {
                    window.location.href = '/login';
                  }
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValidating,
              }) => (
                <Form>
                  <Text m="2" py="2" fontWeight="extrabold" fontSize="2xl">
                    Create a family/Add family head
                  </Text>
                  <MyTextInput label="Name" name="firstName" type="text" />
                  <MyTextInput
                    label="Father's Name"
                    name="fatherName"
                    type="text"
                  />
                  <MyTextInput
                    label="Mother's Name"
                    name="motherName"
                    type="text"
                  />
                  <MyTextInput label="Surname" name="surname" type="text" />
                  <MyTextInput label="Email" name="email" type="text" />
                  <MyTextInput
                    label="Password"
                    name="password"
                    type="password"
                  />
                  <MyTextInput
                    label="Confirm Password"
                    name="passwordConfirmation"
                    type="password"
                  />
                  <Box pt="2" px="2">
                    <Text color="#13344C" px="4">
                      {userCreationStatus}
                    </Text>
                    <Button
                      px="0"
                      my="2"
                      w="100%"
                      color="white"
                      background="#13344C"
                      borderRadius="full"
                      _hover={{
                        color: '#00223E',
                        bg: 'white',
                        border: '1px solid #00223E',
                      }}
                      type="submit"
                      disabled={
                        !Object.keys(touched).length ||
                        (Object.keys(touched).length &&
                          Object.keys(errors).length)
                      }
                    >
                      Create family
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddFamilyHead;
