import { Box, Button, Text, Link } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyTextInput from './../../components/MyTextInput';
import { Link as ReachLink } from 'react-router-dom';
import axios from 'axios';

const ChangePasswordComponent = props => {
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  return (
    <Box>
      <Formik
        initialValues={{
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={Yup.object({
          password: Yup.string().required('Required'),
          passwordConfirmation: Yup.string()
            .required('Required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        })}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          let payload = {
            password: values.password,
            passwordConfirmation: values.passwordConfirmation,
            token: props.token,
            email: props.email,
          };
          try {
            const result = await axios.put(props.api_based_on_user, payload);
            console.log('Result of changing password:', result);
            if (result) {
              setIsPasswordChanged(true);
              resetForm();
            }
          } catch (err) {
            if (err.response.data.errors) {
              setErrors(err.response.data.errors);
            }
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
        }) => (
          <Form>
            <Box maxW={{ sm: '85%', md: '45%', lg: '30%' }} mx="auto" my="10">
              <Text fontSize="xl" my="10" fontWeight="bold" textAlign="center">
                Set New Password
              </Text>

              {!isPasswordChanged ? (
                <>
                  <MyTextInput
                    label="Password"
                    name="password"
                    type="password"
                  />
                  <MyTextInput
                    label="Confirm Password"
                    name="passwordConfirmation"
                    type="password"
                  />
                  <Box mx="2">
                    <Button
                      flex="1"
                      mt="5"
                      w="100%"
                      px="2"
                      background="#13344C"
                      borderRadius="full"
                      type="submit"
                      color="white"
                      _hover={{
                        color: '#00223E',
                        bg: 'white',
                        border: '1px solid #00223E',
                      }}
                      disabled={
                        !Object.keys(touched).length ||
                        (Object.keys(touched).length &&
                          Object.keys(errors).length)
                      }
                    >
                      Save New Password
                    </Button>
                  </Box>
                </>
              ) : (
                <Box mx="2">
                  <Text>Password changed successfully.</Text>
                  <Link mx="auto" as={ReachLink} to="/login">
                    <Button
                      flex="1"
                      mt="5"
                      w="100%"
                      py="2"
                      background="#13344C"
                      borderRadius="full"
                      type="submit"
                      color="white"
                      _hover={{
                        color: '#00223E',
                        bg: 'white',
                        border: '1px solid #00223E',
                      }}
                    >
                      Proceed to Login
                    </Button>
                  </Link>
                </Box>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ChangePasswordComponent;
