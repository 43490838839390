import { Box, FormLabel, Text, Select } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useField } from 'formik';

const MySelect = ({ label, ...props }) => {
  const [field, { error, touched }] = useField(props);
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      <Box
        w="95%"
        post="relative"
        display="inline-block"
        pos="relative"
        border="2px solid"
        borderRadius="2xl"
        borderColor={isFocused ? '#13344C' : '#367A91'}
        color={isFocused ? '#13344C' : '#367A91'}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        m="2"
      >
        <FormLabel
          htmlFor={props.id || props.name}
          pos="absolute"
          fontSize="0.65rem"
          top="0.6rem"
          left="0"
          pt="0.2rem"
          pl="16px"
        >
          {label}
        </FormLabel>
        <Select
          variant="unstyled"
          pt="0.75rem"
          w="100%"
          h="3.25rem"
          pl="16px"
          {...field}
          {...props}
        />
      </Box>
      {touched && error ? (
        <Text color="red.500" pt={0} px={2} ml={2} pb={2} className="error">
          {error}
        </Text>
      ) : null}
    </>
  );
};

export default MySelect;
