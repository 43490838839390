import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link as ReachLink } from 'react-router-dom';
import { API_HOST } from './../constants.js';
import Moment from 'react-moment';
import editIcon from './../assets/edit.svg';
import { Flex, Image } from '@chakra-ui/react';

import {
  Link,
  Box,
  Table,
  Tr,
  Thead,
  Tbody,
  Td,
  Th,
  Spinner,
  Text,
} from '@chakra-ui/react';
import httpClient from './../utils/httpClient.js';
import MemberProfileOverview from './../components/MemberProfileOverview.js';

const ViewOneMember = () => {
  const [userInfo, setUserInfo] = useState();
  const [didWeGetInfo, setDidWeGetInfo] = useState('loading');
  const search = useLocation().search;
  useEffect(() => {
    const getInfo = async () => {
      const id = new URLSearchParams(search).get('id');
      const payload = { id: id };
      try {
        let result = await httpClient({
          method: 'GET',
          url: `${API_HOST}/users/${id}`,
          data: payload,
        });
        if (result) {
          setUserInfo(result.data);
          setDidWeGetInfo('true');
          return true;
        }
      } catch (err) {
        setDidWeGetInfo('false');
        return false;
      }
    };
    getInfo();
  }, [search]);

  const conditionalContentRendering = () => {
    if (didWeGetInfo === 'loading') {
      return (
        <Flex direction="column" alignItems="center">
          <Text my="4">Loading...</Text>
          <Spinner w="20" h="20" />
        </Flex>
      );
    } else if (didWeGetInfo === 'true') {
      return (
        <Box py="4">
          {/* Header */}
          <Box
            mx={{ base: '3%', lg: '10%' }}
            borderRadius="xl"
            mt="4"
            boxShadow="dark-lg"
            position="relative"
          >
            <MemberProfileOverview userInfo={userInfo} />
            <Flex
              position={{ lg: 'absolute' }}
              top="2"
              right="2"
              flexDirection={{ base: 'row', lg: 'column' }}
            >
              {userInfo.canEditProfile ? (
                <Flex my="2" mx="auto">
                  <Link
                    as={ReachLink}
                    to={`/editprofile?id=${userInfo.id}`}
                    mx="auto"
                  >
                    <Image mx="auto" w="8" h="8" src={editIcon} />
                    Edit Details
                  </Link>
                </Flex>
              ) : null}
            </Flex>
          </Box>
          {/* Details table */}
          <Box
            mx={{ base: '3%', lg: '10%' }}
            color="#367A91"
            my="6"
            borderRadius="xl"
            boxShadow="dark-lg"
          >
            <Table>
              <Thead>
                <Tr>
                  <Th>Description</Th>
                  <Th>Info</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td colSpan="2" color="#13344C" fontWeight="bold">
                    Registration Details
                  </Td>
                </Tr>
                <Tr>
                  <Td>Family Number</Td>
                  <Td>{userInfo.familyId}</Td>
                </Tr>
                <Tr>
                  <Td>Individual Enrollment Number</Td>
                  <Td>{userInfo.id}</Td>
                </Tr>
                <Tr>
                  <Td>Role</Td>
                  <Td>
                    {userInfo.roleId === 4
                      ? 'User'
                      : userInfo.roleId === 3
                      ? 'Family Head'
                      : userInfo.roleId === 2
                      ? 'Manager'
                      : 'Administrator'}
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan="2" color="#13344C" fontWeight="bold">
                    Personal Details
                  </Td>
                </Tr>
                <Tr>
                  <Td>Date of Birth</Td>
                  <Td>
                    {userInfo.dob ? (
                      <Moment format="DD MMMM YYYY">{userInfo.dob}</Moment>
                    ) : null}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Gender</Td>
                  <Td>{userInfo.gender}</Td>
                </Tr>
                <Tr>
                  <Td>Education</Td>
                  <Td>{userInfo.education}</Td>
                </Tr>
                <Tr>
                  <Td>Occupation</Td>
                  <Td>{userInfo.occupation}</Td>
                  {/* TO DO */}
                </Tr>
                <Tr>
                  <Td>Blood Group</Td>
                  <Td>{userInfo.bloodgroup}</Td>
                </Tr>
                <Tr>
                  <Td>Clan</Td>
                  <Td>{userInfo.clan}</Td>
                </Tr>
                <Tr>
                  <Td colSpan="2" color="#13344C" fontWeight="bold">
                    Home and Office Address
                  </Td>
                </Tr>
                <Tr>
                  <Td>Home Address</Td>
                  <Td>{userInfo.address}</Td>
                </Tr>
                <Tr>
                  <Td>Office Address</Td>
                  <Td>{userInfo.officeAddress}</Td>
                </Tr>
                <Tr>
                  <Td>Region</Td>
                  <Td>{userInfo.region}</Td>
                </Tr>
                <Tr>
                  <Td>Country</Td>
                  <Td>{userInfo.country}</Td>
                </Tr>
                {userInfo.maritalStatus === 'Married' ||
                userInfo.maritalStatus === 'Widowed' ? (
                  <>
                    <Tr>
                      <Td colSpan="2" color="#13344C" fontWeight="bold">
                        Spouse Details
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Marital Status</Td>
                      <Td>{userInfo.maritalStatus}</Td>
                    </Tr>
                    <Tr>
                      <Td>Wedding Date</Td>
                      <Td>
                        {userInfo.weddingDate ? (
                          <Moment format="DD MMMM YYYY">
                            {userInfo.weddingDate}
                          </Moment>
                        ) : null}
                      </Td>
                    </Tr>
                    {userInfo.gender === 'Male' ? (
                      <>
                        <Tr>
                          <Td>Spouse's Premarriage First Name</Td>
                          <Td>{userInfo.preMarriageFirstName}</Td>
                        </Tr>
                        <Tr>
                          <Td>Spouse's First Name After Marriage</Td>
                          <Td>{userInfo.postMarriagePartnerFirstName}</Td>
                        </Tr>
                      </>
                    ) : (
                      <Tr>
                        <Td>Spouse's First Name</Td>
                        <Td>{userInfo.postMarriagePartnerFirstName}</Td>
                      </Tr>
                    )}
                    <Tr>
                      <Td>Name of Spouse's Mother</Td>
                      <Td>{userInfo.postMarriagePartnerMotherName}</Td>
                    </Tr>
                    <Tr>
                      <Td>Name of Spouse's Father</Td>
                      <Td>{userInfo.postMarriagePartnerFatherName}</Td>
                    </Tr>
                    <Tr>
                      <Td>Spouse's Surname before Marriage</Td>
                      <Td>{userInfo.postMarriagePartnerLastName}</Td>
                    </Tr>
                  </>
                ) : null}
              </Tbody>
            </Table>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box>
          <Text>Oops! No such page exists!</Text>
          <Link as={ReachLink} to="/login">
            Click here to login!
          </Link>
        </Box>
      );
    }
  };

  return <>{conditionalContentRendering()}</>;
};

export default ViewOneMember;
