import {
  Box,
  Button,
  Text,
  Input,
  FormErrorMessage,
  FormControl,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Field } from 'formik';
import axios from 'axios';
import { API_HOST } from './../../constants.js';

const ForgotPassword = () => {
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const validateEmail = value => {
    const emailRegEx = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
    let error;
    if (!value) {
      error = 'We need this information to find your account.';
    } else if (!emailRegEx.test(value)) {
      error = 'Entered email address is incorrect.';
    } else {
      error = '';
    }
    return error;
  };

  return (
    <Box>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={async (values, { setErrors }) => {
          let payload = {
            email: values.email.toLowerCase(),
          };
          try {
            const result = await axios.put(
              `${API_HOST}/users/forgotPassword`,
              payload
            );
            if (result) {
              setIsPasswordReset(true);
            }
          } catch (err) {
            if (err.response.data.errors) {
              setErrors(err.response.data.errors);
            }
          }
        }}
      >
        {props => (
          <Form>
            <Box w={{ sm: '90%', md: '60%', lg: '40%' }} mx="auto" mt="10">
              <Box>
                <Text fontSize="4xl" my="10" fontWeight="bold" mx="2">
                  Reset your vConnect account password
                </Text>
                <Text mx="2" textColor="#367A91">
                  Enter the email associated with your account.
                </Text>
                <Field name="email" validate={validateEmail}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <Input
                        w={{ sm: '90%', md: '60%', lg: '50%' }}
                        m="2"
                        type="text"
                        maxW="70%"
                        border="2px solid"
                        borderRadius="2xl"
                        borderColor="rgb(170, 184, 194)"
                        _focus={{ borderColor: '#13344C' }}
                        my="2"
                        {...field}
                        id="email"
                      />

                      <FormErrorMessage mx="2">
                        {form.errors.email}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Box>
              {isPasswordReset ? (
                <Text color="rgb(29, 161, 242)">
                  An email has been sent to your email address. Please follow
                  the instructions in the email to reset the password.
                </Text>
              ) : (
                <Button
                  mx="2"
                  my="2"
                  background="#13344C"
                  borderRadius="full"
                  type="submit"
                  color="white"
                  _hover={{
                    color: '#00223E',
                    bg: 'white',
                    border: '1px solid #00223E',
                  }}
                >
                  Reset Password
                </Button>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ForgotPassword;
