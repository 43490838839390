import React, { useEffect } from 'react';
import { Box, Text, Image, Link } from '@chakra-ui/react';
import twitter from './../assets/twitter.png';
import facebook from './../assets/facebook.png';
import linkedin from './../assets/linkedin.png';
import httpClient from './../utils/httpClient.js';
import { API_HOST } from './../constants.js';
import profilePicIcon from './../assets/profilePic.svg';
import moment from 'moment';

const MemberProfileOverview = props => {
  useEffect(() => {
    const getProfilePic = async profilePicURL => {
      try {
        const image = await httpClient({
          method: 'GET',
          url: `${API_HOST}/profilepics/${profilePicURL}`,
          responseType: 'blob',
        });
        var reader = new window.FileReader();
        reader.readAsDataURL(image.data);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          document
            .getElementById(`${profilePicURL}`)
            .setAttribute('src', imageDataUrl);
        };
        return null;
      } catch (err) {
        return null;
      }
    };
    if (props.userInfo.profilePic) {
      getProfilePic(props.userInfo.profilePic);
    } else {
      return null;
    }
  }, [props.userInfo.profilePic]);
  return (
    <Box
      px="2"
      display={{ base: 'block', md: 'flex' }}
      py="4"
      alignItems="center"
      minH="20rem"
    >
      <Image
        id={props.userInfo.profilePic}
        ml="auto"
        mr={{ base: 'auto', md: '2' }}
        flex="1"
        maxW={{ base: '100%', md: '300px' }}
        src={profilePicIcon}
        borderRadius="full"
        py="2"
      />
      <Box
        flex="1"
        color="#367A91"
        fontWeight="bold"
        ml="2"
        mr="auto"
        maxWidth="600px"
      >
        <Text fontSize={{ base: 'xl', md: '4xl' }} color="#13344C">
          {props.userInfo.firstName +
            ' ' +
            props.userInfo.motherName +
            ' ' +
            props.userInfo.fatherName +
            ' ' +
            props.userInfo.surname}
        </Text>

        {props.userInfo.education ? (
          <Text fontWeight="bold" py="1">
            {props.userInfo.education}
          </Text>
        ) : null}

        {props.userInfo.occupation ? (
          <Text fontWeight="bold" py="1">
            {props.userInfo.occupation}
          </Text>
        ) : null}

        <Text fontWeight="bold" py="1">
          {props.userInfo.relationWithFamilyHead
            ? props.userInfo.relationWithFamilyHead === 'Myself'
              ? 'Family Head'
              : props.userInfo.relationWithFamilyHead === 'Other'
              ? null
              : props.userInfo.relationWithFamilyHead +
                ' of ' +
                props.userInfo.familyHead.firstName +
                ' ' +
                props.userInfo.familyHead.motherName +
                ' ' +
                props.userInfo.familyHead.fatherName +
                ' ' +
                props.userInfo.familyHead.surname
            : null}
        </Text>

        {props.userInfo.phone ? (
          <Text py="1">{props.userInfo.phone}</Text>
        ) : null}

        {props.userInfo.email ? (
          <Text py="1">{props.userInfo.email}</Text>
        ) : null}

        {props.userInfo.facebookLink ||
        props.userInfo.linkedinLink ||
        props.userInfo.twitterLink ? (
          <Box>
            <Link href={props.userInfo.facebookLink} display="inline-block">
              {props.userInfo.facebookLink ? (
                <Image mr="2" src={facebook} />
              ) : (
                ''
              )}
            </Link>
            <Link href={props.userInfo.linkedinLink} display="inline-block">
              {props.userInfo.linkedinLink ? (
                <Image mx="2" src={linkedin} />
              ) : (
                ''
              )}
            </Link>
            <Link href={props.userInfo.twitterLink} display="inline-block">
              {props.userInfo.twitterLink ? <Image mx="2" src={twitter} /> : ''}
            </Link>
          </Box>
        ) : null}
        <Text py="1">
          Profile Updated On:{' '}
          {moment(props.userInfo.updatedOn).format('MMMM Do YYYY, h:mm:ss a')}
        </Text>
      </Box>
    </Box>
  );
};

export default MemberProfileOverview;
